import { InputHTMLAttributes } from "react";
import styled from "styled-components";

interface SonomaInputProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  inputRef?: any;
  customAlert?: string;
}

export default function FormFilePicker(props: SonomaInputProps) {
  return (
    <>
      <Label htmlFor={props.title}>{props.title}</Label>
      {props.customAlert && <AlertBanner>{props.customAlert}</AlertBanner>}
      <Input {...props} name={props.title} ref={props.inputRef} />
    </>
  );
}

const AlertBanner = styled.p`
  padding: 0.25rem 0;
  font-size: 0.9rem;
  color: red;
`;

const Label = styled.label`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
`;

const Input = styled.input`
  color: ${(props) => props.theme.colors.text_dark};
  width: 250px;
  height: 2rem;
  border: 1px solid ${(props) => props.theme.colors.border};
  margin: 0.5rem 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
  padding: 0.25rem;
  border-radius: 2px;

  &[type="file"] {
    width: auto;
    height: auto;
    padding: 0.5rem;
    cursor: pointer;

    &::-webkit-file-upload-button {
      cursor: pointer;
      border: none;
      background: #fff;
      color: ${(props) => props.theme.colors.text_dark};
    }
  }
`;
