import { useState } from "react";
import Button from "../../components/Button";
import { FaPen, FaTrashAlt } from "react-icons/fa";

export default function ProductRestrictionsListButton({
  id,
  type,
  action,
  month,
}: {
  id: string;
  type: "edit" | "delete";
  action: ({ id, month }: { id: string; month?: number }) => Promise<void>;
  month?: number;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Button
      variant="light"
      onClick={async () => {
        setIsLoading(true);
        await action({ id, month });
        setIsLoading(false);
      }}
      customBackground={"none"}
      disabled={isLoading}
    >
      {isLoading ? (
        "Carregando..."
      ) : type === "edit" ? (
        <FaPen color="gray" />
      ) : (
        <FaTrashAlt color="red" />
      )}
    </Button>
  );
}
