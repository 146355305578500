import { ReactNode } from "react";
import styled from "styled-components";

export default function Modal({
  setOpen,
  isOpen,
  children,
}: {
  setOpen: Function;
  isOpen: boolean;
  children: ReactNode;
}) {
  function handleToggleModal() {
    setOpen((value: boolean) => !value);
  }

  return isOpen ? (
    <>
      <ModalShadow onClick={handleToggleModal} />
      <ModalContainer>{children}</ModalContainer>
    </>
  ) : null;
}

const ModalShadow = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: #000;
  opacity: 20%;
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  z-index: 4;
`;

const ModalContainer = styled.div`
  width: 90%;
  max-width: 600px;
  position: fixed;
  background: #fff;
  border-radius: 8px;
  border: solid 1px ${(props) => props.theme.colors.border};
  padding: 2rem;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  max-height: 100%;
  z-index: 5;
`;
