import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getPedidos, updateOrderStatus } from "../../services/ordersService";
import { OrderT } from "../../services/types";
import { formatDate } from "../../utils/data";
import { formatOrdersToCsv } from "../../utils/orders";
import { formatPrice, getDiscount } from "../../utils/price";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import { toast } from "react-toastify";

const csvHeader = [
  { label: "Codigo do pedido", key: "codpedido" },
  { label: "Data pedido", key: "datapedido" },
  { label: "Método pag.", key: "pagamento" },
  { label: "Status do pedido", key: "status" },
  { label: "Nome Cliente", key: "nome" },
  { label: "CPF Cliente", key: "cpf" },
  { label: "Endereço", key: "endereco" },
  { label: "Complemento", key: "complemento" },
  { label: "Bairro", key: "bairro" },
  { label: "CEP", key: "cep" },
  { label: "Produtos", key: "produtos" },
  { label: "Valor frete", key: "frete" },
  { label: "Cupom", key: "cupom" },
  { label: "Descontos", key: "descontos" },
  { label: "Valor produtos", key: "total_produtos" },
  { label: "Valor pedido", key: "total" },
];

const itemPerPage = [10, 20, 50, 100];

const STATUS_TO_SHOW_BUTTON_UPDATE_PAYMENT = [
  "Aguardando aprovação da plataforma",
];

// Definir interface para o StatusBadge
interface StatusBadgeProps {
  status: string;
}

const StatusBadge = styled.span<StatusBadgeProps>`
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  background-color: ${props => getStatusColor(props.status)};
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  display: inline-block;
  line-height: 1;
  text-transform: capitalize;
`;

// Ajustar a função getStatusColor para ter labels mais curtos
const getStatusColor = (status: string): string => {
  if (!status) return '#7f8c8d';

  type StatusColors = {
    [key: string]: string;
  };

  // Simplificar os status para melhor visualização
  const statusMap: { [key: string]: string } = {
    'Aguardando aprovação da plataforma': 'Aguardando Aprovação',
    'Aguardando confirmação de entrega': 'Aguardando Entrega',
    'Aguardando embarque': 'Aguardando Embarque',
    'Aguardando faturamento': 'Aguardando Faturamento',
    'Aguardando pagamento': 'Aguardando Pagamento',
    'Cancelado': 'Cancelado'
  };

  const statusColors: StatusColors = {
    'aguardando aprovação': '#FFA500',
    'aguardando entrega': '#3498db',
    'aguardando embarque': '#f1c40f',
    'aguardando faturamento': '#e67e22',
    'aguardando pagamento': '#e74c3c',
    'cancelado': '#95a5a6',
    'default': '#7f8c8d'
  };

  const simplifiedStatus = statusMap[status] || status;
  return statusColors[simplifiedStatus.toLowerCase().split(' ')[0]] || statusColors.default;
};

const calculateOrderTotal = (order: OrderT): number => {
  // Soma total dos produtos (preço * quantidade)
  const productsTotal = order.produtos.reduce((total, product) => {
    return total + (Number(product.preco) * product.quantidade);
  }, 0);

  // Adiciona frete se existir
  const freightPrice = order.freight?.price ? Number(order.freight.price) : 0;

  // Calcula descontos
  const discounts = order.cupom ? getDiscount(order.cupom, order.valores.produtos, order) : 0;

  // Retorna o total final
  return productsTotal + freightPrice - discounts;
};

// Adicionar função para calcular o total monetário
const calculateTotalAmount = (orders: OrderT[]): number => {
  return orders.reduce((total, order) => total + calculateOrderTotal(order), 0);
};

export default function OrderList() {
  const navigator = useNavigate();

  const [orders, setOrders] = useState<OrderT[]>([]);
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState<string>("");

  const [ref, setRef] = useState("");
  const [minDate, setminDate] = useState("");
  const [maxDate, setmaxDate] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limitQuantity, setLimitQuantity] = useState<number>(itemPerPage[0]);
  const [resultsTotal, setResultsTotal] = useState<number>(0);
  const [pagesTotal, setPagesTotal] = useState<number>(0);

  const getOrders = async ({
    limit,
    page,
    ref,
    minDate,
    maxDate,
    saveOnContext = false,
  }: {
    limit: number;
    page: number;
    ref?: string;
    minDate: string;
    maxDate: string;
    saveOnContext?: boolean;
  }) => {
    setLoading(true);

    try {
      const {
        pedidos: _orders,
        pages,
        total,
      } = await getPedidos({ limit, page, ref, minDate, maxDate, status });

      // Garantir que não há pedidos duplicados usando Set
      if (saveOnContext) {
        const orderIds = new Set(_orders.map((order: OrderT) => order.codPedido));
        const uniqueOrders = Array.from(orderIds).map((codPedido) => 
          _orders.find((order: OrderT) => order.codPedido === codPedido)
        ).filter((order): order is OrderT => order !== undefined);
        
        setOrders(uniqueOrders);
      }

      setPagesTotal(pages);
      setResultsTotal(total);

      return Promise.resolve(_orders);
    } catch (error) {
      toast.error("Erro ao carregar pedidos");
      return Promise.resolve([]);
    } finally {
      setLoading(false);
    }
  };

  const [csvOrders, setCsvOrders] = useState([]);

  async function fetchOrders() {
    await getOrders({
      limit: limitQuantity,
      page: pageNumber,
      maxDate,
      minDate,
      ref,
      saveOnContext: true,
    });

    // get all orders to CSV
    const _orders = await getOrders({
      limit: limitQuantity,
      page: pageNumber,
      maxDate,
      minDate,
      ref,
    });

    setCsvOrders(_orders);
  }

  useEffect(() => {
    fetchOrders();
  }, [limitQuantity, pageNumber]);

  const handleUpdateStatusPayment = async (order: OrderT) => {
    setLoading((prevState) => !prevState);
    await updateOrderStatus(order.codPedido);
    fetchOrders();
  };

  // Adicionar função para handle do Enter
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      fetchOrders();
    }
  };

  return (
    <Main>
      <Header>
        <h1>Gerenciamento de Pedidos</h1>
        <FiltersWrapper>
          <SearchContainer>
            <SearchInputWrapper>
              <SearchIcon />
              <SearchInput
                value={ref}
                onChange={(e) => setRef(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Buscar por código, email ou cliente..."
                aria-label="Buscar pedidos"
              />
            </SearchInputWrapper>
          </SearchContainer>

          <FiltersGrid>
            <FilterGroup>
              <Label htmlFor="minDate">Data inicial</Label>
              <DateInput
                id="minDate"
                type="date"
                value={minDate}
                onChange={(e) => setminDate(e.target.value)}
              />
            </FilterGroup>

            <FilterGroup>
              <Label htmlFor="maxDate">Data final</Label>
              <DateInput
                id="maxDate"
                type="date"
                value={maxDate}
                onChange={(e) => setmaxDate(e.target.value)}
              />
            </FilterGroup>

            <FilterGroup>
              <Label htmlFor="status">Status</Label>
              <Select
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Todos os status</option>
                <option value="Aguardando aprovação da plataforma">Aguardando aprovação</option>
                <option value="Aguardando confirmação de entrega">Aguardando entrega</option>
                <option value="Aguardando embarque">Aguardando embarque</option>
                <option value="Aguardando faturamento">Aguardando faturamento</option>
                <option value="Aguardando pagamento">Aguardando pagamento</option>
                <option value="Cancelado">Cancelado</option>
              </Select>
            </FilterGroup>

            <Button 
              variant="light"
              onClick={fetchOrders}
              aria-label="Aplicar filtros"
            >
              Buscar Pedidos
            </Button>
          </FiltersGrid>
        </FiltersWrapper>
      </Header>

      <Content>
        {loading ? (
          <LoadingState>
            <Spinner />
            <p>Carregando pedidos...</p>
          </LoadingState>
        ) : orders?.length > 0 ? (
          <>
            <ActionsBar>
              <TotalsInfo>
                <TotalCard>
                  <span className="label">Total de Pedidos</span>
                  <span className="value">{resultsTotal}</span>
                </TotalCard>
                <TotalCard>
                  <span className="label">Valor Total (exibido na página)</span>
                  <span className="value">{formatPrice(calculateTotalAmount(orders))}</span>
                </TotalCard>
              </TotalsInfo>

              <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <ExportButton
                  data={formatOrdersToCsv(csvOrders)}
                  headers={csvHeader}
                  filename={"pedidos_app.csv"}
                >
                  Exportar Pedidos (CSV)
                </ExportButton>
                
                <Pagination
                  totalPages={pagesTotal}
                  currentPage={pageNumber}
                  setCurrentPage={setPageNumber}
                  itemsPerPageOptions={itemPerPage}
                  onItemsPerPageChange={setLimitQuantity}
                  itemsPerPage={limitQuantity}
                  resultsTotal={resultsTotal}
                />
              </div>
            </ActionsBar>

            <TableWrapper>
              <Table role="table" aria-label="Lista de Pedidos">
                <thead>
                  <tr>
                    <Th>Código</Th>
                    <Th>Cliente</Th>
                    <Th>Data</Th>
                    <Th>Total</Th>
                    <Th>Qtd.</Th>
                    <Th>Produtos</Th>
                    <Th>Status</Th>
                    <Th>Ações</Th>
                    <Th>Última Alteração</Th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <TableRow key={order.codPedido}>
                      <Td>
                        <OrderLink href={`/order/${order.codPedido}`}>
                          {order.codPedido}
                        </OrderLink>
                      </Td>
                      <Td>{order.cliente.nome}</Td>
                      <Td>{formatDate(order.createdAt)}</Td>
                      <Td>{formatPrice(calculateOrderTotal(order))}</Td>
                      <Td>{order.produtos.reduce((acc, curr) => acc + curr.quantidade, 0)}</Td>
                      <Td>
                        <ProductsList>
                          {order.produtos.map((product, index) => (
                            <ProductItem key={`${order.codPedido}-${product.nome}-${index}`}>
                              <Quantity>{product.quantidade}x</Quantity>
                              <ProductName>{product.nome}</ProductName>
                            </ProductItem>
                          ))}
                        </ProductsList>
                      </Td>
                      <Td>
                        <StatusBadge status={order.status || ''}>
                          {getStatusMap(order.status)}
                        </StatusBadge>
                      </Td>
                      <Td>
                        {STATUS_TO_SHOW_BUTTON_UPDATE_PAYMENT.includes(order.status) && (
                          <ActionButton
                            onClick={() => handleUpdateStatusPayment(order)}
                            aria-label="Atualizar status do pagamento"
                          >
                            Atualizar
                          </ActionButton>
                        )}
                      </Td>
                      <Td>{formatDate(order.updatedAt)}</Td>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </>
        ) : (
          <EmptyState>
            <p>Nenhum pedido encontrado</p>
            <Button variant="light" onClick={() => {
              setRef("");
              setStatus("");
              setminDate("");
              setmaxDate("");
              fetchOrders();
            }}>
              Limpar Filtros
            </Button>
          </EmptyState>
        )}
      </Content>
    </Main>
  );
}

// Styled Components
const Main = styled.main`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.header`
  margin-bottom: 2rem;

  h1 {
    font-size: 1.5rem;
    color: ${props => props.theme.colors.text_dark};
    margin-bottom: 1.5rem;
  }
`;

const FiltersWrapper = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const SearchContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    left: 1rem;
    color: ${props => props.theme.colors.text_dark}80;
  }
`;

const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  align-items: end;
`;

const FilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-size: 0.875rem;
  color: ${props => props.theme.colors.text_dark};
  font-weight: 500;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: fixed;
  font-size: 1rem;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: ${props => props.theme.colors.background_dark}10;
  }
`;

const Th = styled.th`
  padding: 0.5rem;
  font-weight: 600;
  color: ${props => props.theme.colors.text_dark};
`;

const Td = styled.td`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding: 0.75rem 0.5rem;
`;

const CellProduct = styled.td`
  border-bottom: 1px solid #d6d6d6;
  font-size: 14px;
  font-weight: 400;

  overflow-y: scroll;
`;

const DivTeste = styled.div`
  max-height: 100px;

  span {
    font-weight: 700;
  }

  p {
    margin-bottom: 10px;
  }
`;

const StyledLink = styled.a`
  color: blue;
  text-decoration: underline;
  &:visited {
    color: blue;
    text-decoration: underline;
  }
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;

const DateContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DatePicker = styled.input.attrs({
  type: "date",
})``;

const SearchInput = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0 1rem 0 2.75rem;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;

  &::placeholder {
    color: ${props => props.theme.colors.text_dark}80;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 3px ${props => props.theme.colors.primary}20;
    outline: none;
  }
`;

const ApplyButton = styled.button`
  width: 12.5rem;
  height: 2.5rem;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #7bc55e;
  }
`;

const UpdatePayment = styled.button`
  width: 5rem;
  height: 2rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #7bc55e;
  }
`;

const LeftFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
`;

const DownloadOrdersButton = styled(CSVLink)`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;
`;

const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  margin-top: 1rem;
`;

const ProductsList = styled.ul`
  list-style: none;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
`;

const ProductItem = styled.li`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`;

const Quantity = styled.span`
  font-weight: 700;
  margin-right: 0.5rem;
`;

const ProductName = styled.span`
  font-size: 0.875rem;
`;

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.1);
  }
`;

const LoadingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid ${props => props.theme.colors.text_light};
  border-top: 4px solid ${props => props.theme.colors.text_dark};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
  color: ${props => props.theme.colors.text_dark}80;
`;

const ActionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  background: ${props => props.theme.colors.background_light};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
`;

const TotalsInfo = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const TotalCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  span.label {
    font-size: 0.875rem;
    color: ${props => props.theme.colors.text_dark}80;
  }

  span.value {
    font-size: 1.125rem;
    font-weight: 600;
    color: ${props => props.theme.colors.text_dark};
  }
`;

const ExportButton = styled(CSVLink)`
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.1);
  }
`;

const Content = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const DateInput = styled.input.attrs({ type: "date" })`
  height: 2.5rem;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
`;

const Select = styled.select`
  height: 2.5rem;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
`;

const OrderLink = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-weight: 500;
  
  &:hover {
    text-decoration: underline;
  }
`;

// Componente do ícone de busca
const SearchIcon = () => (
  <svg 
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" 
      fill="currentColor"
    />
  </svg>
);

// Função helper para mapear os status para versões mais curtas
const getStatusMap = (status: string): string => {
  if (!status) return 'Status Desconhecido';
  
  const statusMap: { [key: string]: string } = {
    'Aguardando aprovação da plataforma': 'Aguardando Aprovação',
    'Aguardando confirmação de entrega': 'Aguardando Entrega',
    'Aguardando embarque': 'Aguardando Embarque',
    'Aguardando faturamento': 'Aguardando Faturamento',
    'Aguardando pagamento': 'Aguardando Pagamento',
    'Cancelado': 'Cancelado'
  };

  return statusMap[status] || status;
};
