import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";
import { generateRandomPassword } from "../utils/generateRandomPassword";

export async function createSeller({
  name,
  email,
}: {
  name: string;
  email: string;
}) {
  try {
    const password = generateRandomPassword();
    const data = { name, email, password };
    const result = await axios.post(
      `${configs.apiBaseUrl}/api/createSeller`,
      data
    );
    if (result.status === 200) {
      toast.success("Seller criado com sucesso");
    } else {
      toast.error(`Erro ao criar seller: ${result.status}`);
    }
  } catch (error: any) {
    toast.error(`${error.response.data.message}, ${error.response.status}`);
  }
}
