import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import {
  getProductsWithPromotion,
  createOrEditPromotion,
  deletePromotion,
} from "../../services/promotionalProductsService";
import Modal from "../../components/Modal";
import Swal from "sweetalert2";
import Input from "../../components/BannerManager/FormInput";
import { toast } from "react-toastify";
import { formatDate } from "../../utils/data";
import ProductSelectorForRestriction from "../ProductRestrictions/ProductSelectorForRestriction";
import getImageDownloadUrlFromFireBase from "../../utils/getImageDownloadUrlFromFireBase";
import theme from "../../styles/colors";

async function confirmModal(fn: (...args: any) => Promise<void>) {
  const result = await Swal.fire({
    title: "Tem certeza que deseja excluir?",
    text: "Esta ação não pode ser desfeita",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, tenho certeza!",
    reverseButtons: true,
  });

  if (result.isConfirmed) {
    return fn();
  }
}

function formatPrice(price: string, template?: boolean) {
  if (!price) return "";

  // convert to number to remove trailing zeros - backend uses decimal(15,7)
  const decimalFixed = Number(price).toFixed(2);

  if (!template) return decimalFixed;
  return `R$ ${decimalFixed.toString().replace(".", ",")}`;
}

async function formatProducts(products: any[]) {
  const formatted = [];
  for (let i = 0; i < products.length; i++) {
    const prod = products[i];

    prod.pro_de_promotional_precopor = formatPrice(
      prod.pro_de_promotional_precopor,
      false
    );
    prod.pro_de_promotional_precoatacado = formatPrice(
      prod.pro_de_promotional_precoatacado,
      false
    );

    formatted.push({
      imageUrl: await getImageDownloadUrlFromFireBase(
        prod.imagens[0].pim_st_imagesrc
      ),
      nome: prod.pro_st_nomesite,
      ...prod,
    });
  }

  return formatted;
}

export default function PromotionalProducts() {
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [productData, setProductData] = useState({
    promotionalUnidade: "",
    promotionalCaixa: "",
    promotionStart: "",
    promotionEnd: "",
  });

  const { promotionalUnidade, promotionalCaixa, promotionStart, promotionEnd } =
    productData;

  const cleanFields = () => {
    setProductData({
      ...productData,
      promotionalUnidade: "",
      promotionalCaixa: "",
      promotionStart: "",
      promotionEnd: "",
    });
    setSelectedProduct({});
  };

  const handleInputChange = (field: string, value: string) => {
    const formatted =
      field === "promotionalUnidade" || field === "promotionalCaixa"
        ? value.replace(".", ",")
        : value;

    setProductData({
      ...productData,
      [field]: formatted,
    });
  };

  const fetchProducts = async () => {
    setLoading(true);

    try {
      const products = await getProductsWithPromotion();
      const formatted = await formatProducts(products);

      setProducts(formatted);
      setLoading(false);
    } catch (error) {
      toast.error(
        `Não foi possível buscar produtos promocionais\nErro:\n${error}`
      );
    }

    setLoading(false);
  };

  const createOrUpdateItem = async (item: any) => {
    setLoading(true);

    try {
      await createOrEditPromotion(item);
      await fetchProducts();
    } catch (error) {
      toast.error(`Erro ao atualizar preço promocional\nErro:\n${error}`);
    }

    setLoading(false);
  };

  const deletePromotionalPrice = async (prod: any) => {
    setLoading(true);

    try {
      await deletePromotion(prod.pro_in_codigo);
      await fetchProducts();
    } catch (error) {
      toast.error(`Erro ao excluir preço promocional\nErro:\n${error}`);
    }

    setLoading(false);
  };

  const submitForm = async () => {
    if (!selectedProduct) return toast.error("Erro ao selecionar produto!");

    if (!promotionalUnidade || !promotionStart || !promotionEnd) {
      return toast.warn("Preencha os campos obrigatorios*");
    }

    if (promotionStart > promotionEnd) {
      return toast.warn(
        "O inicio da promoção precisa ser anterior ao fim da promoção."
      );
    }

    await createOrUpdateItem({
      pro_in_codigo: selectedProduct.productId,
      pro_de_promotional_precopor: promotionalUnidade.replace(",", "."),
      pro_de_promotional_precoatacado: promotionalCaixa.replace(",", "."),
      pro_dt_promotion_start: `${promotionStart}T00:00:00.000Z`,
      pro_dt_promotion_end: `${promotionEnd}T00:00:00.000Z`,
    });

    setOpenModal(false);
    cleanFields();
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (loading) {
    return (
      <section className="wrapper">
        <div>carregando...</div>
      </section>
    );
  }

  return (
    <section className="wrapper">
      <Row>
        <Button
          style={{ marginRight: 10, marginBottom: 32 }}
          variant={"dark"}
          onClick={() => {
            setIsEdit(false);
            cleanFields();
            setOpenModal(true);
          }}
        >
          Adicionar
        </Button>
      </Row>
      {products?.length > 0 ? (
        <Table>
          <HeaderCell>SKU</HeaderCell>
          <HeaderCell>Foto</HeaderCell>
          <HeaderCell>Nome do produto</HeaderCell>
          {/* <HeaderCell>Estoque</HeaderCell> */}
          <HeaderCell>Preço de </HeaderCell>
          <HeaderCell>Preço por</HeaderCell>
          <HeaderCell>Preço unidade promocional</HeaderCell>
          <HeaderCell>Preço caixa</HeaderCell>
          <HeaderCell>Preço caixa promocional</HeaderCell>
          <HeaderCell>Data de</HeaderCell>
          <HeaderCell>Data até</HeaderCell>
          <HeaderCell>Ações</HeaderCell>
          {products?.map((prod) => {
            return (
              <Row>
                <Cell>{prod.pro_st_sku}</Cell>
                <Cell>
                  <img
                    style={{ width: 75, height: 75, marginRight: 16 }}
                    src={prod.imageUrl}
                    alt=""
                  />
                </Cell>
                <Cell>{prod.pro_st_nomesite}</Cell>
                {/* <Cell>{prod.estoque}</Cell> */}
                <Cell>{formatPrice(prod.pro_de_precode, true)}</Cell>
                <Cell>{formatPrice(prod.pro_de_precopor, true)}</Cell>
                <Cell>
                  {formatPrice(prod.pro_de_promotional_precopor, true)}
                </Cell>
                <Cell>{formatPrice(prod.pro_de_precoatacado, true)}</Cell>
                <Cell>
                  {formatPrice(prod.pro_de_promotional_precoatacado, true)}
                </Cell>
                <Cell>{formatDate(prod.pro_dt_promotion_start)}</Cell>
                <Cell>{formatDate(prod.pro_dt_promotion_end)}</Cell>
                <Cell>
                  <Button
                    onClick={() => {
                      setIsEdit(true);
                      setOpenModal(true);
                      setSelectedProduct({
                        productId: prod.pro_in_codigo,
                        ...prod,
                      });
                      setProductData({
                        promotionalUnidade:
                          prod.pro_de_promotional_precopor.replace(".", ","),
                        promotionalCaixa:
                          prod.pro_de_promotional_precoatacado.replace(
                            ".",
                            ","
                          ),
                        promotionStart:
                          prod.pro_dt_promotion_start.split("T")[0],
                        promotionEnd: prod.pro_dt_promotion_end.split("T")[0],
                      });
                    }}
                    variant={"light"}
                  >
                    Editar
                  </Button>{" "}
                  <Button
                    onClick={() =>
                      confirmModal(() => deletePromotionalPrice(prod))
                    }
                    variant={"light"}
                    customBackground={theme.colors.wine_red}
                  >
                    Excluir
                  </Button>
                </Cell>
              </Row>
            );
          })}
        </Table>
      ) : (
        <div> nenhum produto encontrado</div>
      )}

      <Modal isOpen={openModal} setOpen={setOpenModal}>
        <Form>
          <strong>
            {isEdit
              ? `Editando SKU: ${selectedProduct.pro_st_sku}`
              : `Criar novo preço promocional`}
          </strong>

          {!isEdit && (
            <ProductSelectorForRestriction
              setProd={(e) => {
                console.log("[PRODUCT]", e);
                setSelectedProduct(e);
              }}
            />
          )}

          {selectedProduct && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 32,
                marginTop: isEdit ? 32 : -16,
              }}
            >
              <img
                style={{ width: 75, height: 75, marginRight: 16 }}
                src={selectedProduct.imageUrl}
                alt=""
              />
              <p>{selectedProduct.nome}</p>
            </div>
          )}

          <FormRow>
            <div>
              <Input
                required={true}
                title="Preço promocional unitário* "
                setValue={(value: string) =>
                  handleInputChange("promotionalUnidade", value)
                }
                type="text"
                value={promotionalUnidade}
                width="250px"
                height="2rem"
                borderRadius="2px"
              />
              <br />

              <Input
                required={true}
                title="Preço promocional caixa "
                setValue={(value: string) =>
                  handleInputChange("promotionalCaixa", value)
                }
                type="text"
                value={promotionalCaixa}
                width="250px"
                height="2rem"
                borderRadius="2px"
              />
              <br />

              <Input
                required={true}
                title="Data início promoção* "
                setValue={(value: string) =>
                  handleInputChange("promotionStart", value)
                }
                type="date"
                value={promotionStart}
                width="250px"
                height="2rem"
                borderRadius="2px"
              />
              <br />

              <Input
                required={true}
                title="Data término promoção* "
                setValue={(value: string) =>
                  handleInputChange("promotionEnd", value)
                }
                type="date"
                value={promotionEnd}
                width="250px"
                height="2rem"
                borderRadius="2px"
              />
              <br />
              <p
                style={{
                  fontStyle: "italic",
                  padding: "0.2rem 0rem 0.2rem 0rem",
                  fontSize: "14px",
                }}
              >
                Campos com * são obrigatorios
              </p>
            </div>
          </FormRow>
          <Button
            onClick={async () => {
              await submitForm();
            }}
            variant="dark"
          >
            Salvar
          </Button>
        </Form>
      </Modal>
    </section>
  );
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  strong {
    font-size: 1.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;

const FormRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
