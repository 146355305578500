import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../components/Button";
import {
  createDictionaryItem,
  deleteDictionaryItem,
  editDictionaryItem,
  getPontuacoes,
  getTypesDictionary,
} from "../../services/dictionaryService";
import Modal from "../../components/Modal";
import Swal from "sweetalert2";
import TextArea from "../../components/BannerManager/FormTextArea";
import Dropdown from "../../components/BannerManager/Dropdown";
import { toast } from "react-toastify";
import theme from "../../styles/colors";

async function confirmModal(fn: (...args: any) => Promise<void>) {
  const result = await Swal.fire({
    title: "Tem certeza que deseja excluir?",
    text: "Esta ação não pode ser desfeita",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Sim, tenho certeza!",
    reverseButtons: true,
  });

  if (result.isConfirmed) {
    return fn();
  }
}

function formatTerm(termo: string) {
  return termo.toUpperCase().replace("PON_IN_", "");
}

export default function DictionaryList() {
  const [dictionary, setDictionary] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [selectedTerm, setSelectedTerm] = useState<any>({});
  const [descricao, setDescricao] = useState("");

  const [availableTerms, setAvailableTerms] = useState<any[]>([]);
  const [newTerm, setNewTerm] = useState<any>({});

  const fetchDictionary = async () => {
    setLoading(true);

    try {
      const dictionaries = await getTypesDictionary();

      setDictionary(dictionaries);
      setLoading(false);
    } catch (error) {
      toast.error(`Não foi possível buscar dicionário\nErro:\n${error}`);
    }

    setLoading(false);
  };

  const fetchPontuacoes = async () => {
    setLoading(true);

    try {
      const pontuacoes = await getPontuacoes();
      const formatted = pontuacoes.map((pon: any) => ({
        value: pon,
        title: formatTerm(pon),
      }));

      setAvailableTerms(formatted);
    } catch (error) {
      toast.error(`Erro ao buscar pontuações\nErro:\n${error}`);
    }

    setLoading(false);
  };

  const updateItem = async (item: any) => {
    setLoading(true);

    try {
      await editDictionaryItem(item);
      await fetchDictionary();
    } catch (error) {
      toast.error(`Erro ao atualizar dicionário de selo\nErro:\n${error}`);
    }

    setLoading(false);
  };

  const deleteItem = async (item: any) => {
    setLoading(true);

    try {
      await deleteDictionaryItem(item.dic_st_termo);
      await fetchDictionary();
    } catch (error) {
      toast.error(`Erro ao excluir dicionário de selo\nErro:\n${error}`);
    }

    setLoading(false);
  };

  const createItem = async (item: any) => {
    setLoading(true);

    try {
      await createDictionaryItem(item);
      await fetchDictionary();
    } catch (error) {
      toast.error(`Erro ao criar dicionário de selo\nErro:\n${error}`);
    }

    setLoading(false);
  };

  const cleanFields = () => {
    setSelectedTerm(null);
    setDescricao("");
    setNewTerm(null);
  };

  const submitForm = async () => {
    if (isEdit) {
      await updateItem({
        ...selectedTerm,
        dic_st_descricao: descricao,
      });
    } else {
      await createItem({
        dic_st_termo: newTerm,
        dic_st_descricao: descricao,
        dic_en_tipo: "PONTUACAO",
      });
    }

    cleanFields();
  };

  useEffect(() => {
    fetchPontuacoes();
    fetchDictionary();
  }, []);

  if (loading) {
    return (
      <section className="wrapper">
        <div>carregando...</div>
      </section>
    );
  }

  return (
    <section className="wrapper">
      <Row>
        <Button
          style={{ marginRight: 10, marginBottom: 32 }}
          variant={"dark"}
          onClick={() => {
            setIsEdit(false);
            cleanFields();
            setOpenModal(true);
          }}
        >
          Adicionar
        </Button>
      </Row>
      {dictionary?.length > 0 ? (
        <Table>
          <HeaderCell>Pontuação</HeaderCell>
          <HeaderCell>Descrição</HeaderCell>
          <HeaderCell>Ações</HeaderCell>
          {dictionary?.map((item) => {
            return (
              <Row>
                <Cell>{formatTerm(item.dic_st_termo)}</Cell>
                <Cell>{item.dic_st_descricao}</Cell>
                <Cell>
                  <Button
                    onClick={() => {
                      setIsEdit(true);
                      setOpenModal(true);
                      setSelectedTerm(item);
                      setDescricao(item.dic_st_descricao);
                    }}
                    variant="light"
                  >
                    Editar
                  </Button>{" "}
                  <Button
                    onClick={() => confirmModal(() => deleteItem(item))}
                    variant="light"
                    customBackground={theme.colors.wine_red}
                  >
                    Excluir
                  </Button>
                </Cell>
              </Row>
            );
          })}
        </Table>
      ) : (
        <div> nenhum produto encontrado</div>
      )}

      <Modal isOpen={openModal} setOpen={setOpenModal}>
        <Form>
          <strong>
            {isEdit
              ? `Editando: ${selectedTerm?.dic_st_termo}`
              : `Criar novo dicionário de selo`}
          </strong>

          {!isEdit && (
            <Dropdown
              required={true}
              title={`Escolha o tipo selo`}
              selected={newTerm}
              setSelected={setNewTerm}
              options={availableTerms}
            />
          )}

          <TextArea
            required={true}
            title="Descrição"
            setValue={setDescricao}
            type="text"
            value={descricao}
            maxLength={255}
            style={{ minHeight: 100, maxHeight: 450, resize: "vertical" }}
          />

          <div style={{ display: "flex", gap: 16 }}>
            <Button
              onClick={async () => {
                await submitForm();
                setOpenModal(false);
              }}
              variant="light"
            >
              Salvar
            </Button>
            <Button
              onClick={() => {
                cleanFields();
                setOpenModal(false);
              }}
              variant="light"
              customBackground={theme.colors.wine_red}
            >
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal>
    </section>
  );
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  strong {
    font-size: 1.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;
