import { useState } from "react";
import styled from "styled-components";
import theme from "../styles/colors";
import MenuIconSvg from "./MenuIconSvg";
import MenuItem, { Link as MenuLink } from "./MenuItem";

export function NavMenu() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const emailsCX = [
    "rafaela.nascimento@sonoma.com.br",
    "cleciane.oliveira@sonoma.com.br",
    "weslei.andrade@sonoma.com.br",
    "rarime.oliveira@sonoma.com.br",
    "giovanna.silva@sonoma.com.br",
    "isabela.lima@sonoma.com.br",
    "thiago.oliveira@sonoma.com.br",
  ];

  const storedEmail = localStorage.getItem("Email");

  function toggleMenu() {
    setIsOpen((state) => !state);
  }

  return (
    <NavContainer>
      {/* Menu Button and Sidebar primeiro */}
      <Button
        onClick={toggleMenu}
        aria-label="Abrir menu de navegação"
        aria-expanded={isOpen}
      >
        <MenuIconSvg />
      </Button>

      {/* Header Menu Items depois */}
      <HeaderItems>
        <MenuItem to={"/dashboard"} title={"Relatórios"} />
        <MenuItem to={"/banners"} title={"Banners"} />
        <MenuItem to={"/orders"} title={"Pedidos"} />
        <MenuItem to={"/clustersList"} title={"Coleções"} />
        <MenuItem to={"/couponsList"} title={"Cupons"} />
      </HeaderItems>

      {/* Overlay */}
      <Overlay isOpen={isOpen} onClick={toggleMenu} />

      {storedEmail && emailsCX.includes(storedEmail) ? (
        <Nav isOpen={isOpen} role="navigation" aria-label="Menu do CX">
          <Button
            onClick={toggleMenu}
            className="closeButton"
            aria-label="Fechar menu"
          >
            <span>Fechar</span>
          </Button>

          <MenuSection>
            <MenuItem to={"/cx/orders"} title={"Pedidos"} />
            <MenuItem to={"/cx/couponsList"} title={"Cupons"} />
            <MenuItem to={"/cx/freight"} title={"Frete"} />
            <MenuItem to={"/cx/freight/create"} title={"Upload Frete"} />
            <MenuItem to={"/cx/freight/list"} title={"Lista Frete Update"} />
            <MenuItem to={"/cx/orders"} title={"Pedidos"} />
          </MenuSection>
        </Nav>
      ) : (
        <Nav isOpen={isOpen} role="navigation" aria-label="Menu principal">
          <Button
            onClick={toggleMenu}
            className="closeButton"
            aria-label="Fechar menu"
          >
            <span>Fechar</span>
          </Button>

          <MenuContent>
            <MenuSection>
              <MenuTitle>Relatórios e Análises</MenuTitle>
              <MenuItem to={"/dashboard"} title={"Relatórios"} />
              <MenuItem to={"/audit/orders"} title={"Auditoria"} />
              <MenuItem
                to={"/reports/signature"}
                title={"Signature Dashboard"}
              />
              <MenuItem to={"/sales/dashboard"} title={"Dashboard De Vendas"} />
            </MenuSection>

            <MenuSection>
              <MenuTitle>Gestão de Pedidos</MenuTitle>
              <MenuItem to={"/orders"} title={"Pedidos"} />
              <MenuItem to={"/splitted"} title={"Pedidos Splitted"} />
              <MenuItem to={"/client/carts"} title={"Carrinhos Abandonados"} />
            </MenuSection>

            <MenuSection>
              <MenuTitle>Fretes</MenuTitle>
              <MenuItem to={"/freight"} title={"Frete"} />
              <MenuItem to={"/cx/freight/create"} title={"Upload Frete"} />
              <MenuItem to={"/cx/freight/list"} title={"Lista Frete Update"} />
            </MenuSection>

            <MenuSection>
              <MenuTitle>Produtos e Catálogo</MenuTitle>
              <MenuItem to={"/clustersList"} title={"Coleções"} />
              <MenuItem to={"/categories"} title={"Categorias"} />
              <MenuItem to={"/kits"} title={"Kits"} />
              <MenuItem
                to={"/restrictions"}
                title={"Regionalização de produtos"}
              />
              <MenuItem
                to={"/promotional_products"}
                title={"Produtos promocionais"}
              />
            </MenuSection>

            <MenuSection>
              <MenuTitle>Marketing e Promoções</MenuTitle>
              <MenuItem to={"/banners"} title={"Banners"} />
              <MenuItem to={"/couponsList"} title={"Cupons"} />
              <MenuItem
                to={"/promotions/shipping/all"}
                title={"Fretes promocionais"}
              />
              <MenuItem to={"/freight"} title={"Frete"} />
            </MenuSection>

            <MenuSection>
              <MenuTitle>Outros</MenuTitle>
              <MenuItem to={"/signature"} title={"Signature App"} />
              <MenuItem to={"/dictionary"} title={"Dicionário"} />
              <MenuItem to={"/createLink"} title={"Criar Link"} />
              <MenuItem to={"/sellerCenter"} title={"Seller Center"} />
              <MenuItem to={"/clients"} title={"Clientes"} />
              <MenuItem to={"/strip"} title={"Gerenciar Tarja"} />
            </MenuSection>
          </MenuContent>
        </Nav>
      )}
    </NavContainer>
  );
}

const HeaderItems = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;

  ${MenuLink} {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;

    &:hover {
      background: none;
    }

    &.active {
      background: none;

      &::before {
        display: none;
      }
    }
  }
`;

const NavContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MenuSection = styled.div`
  padding: 0.5rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.background_dark}20;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
`;

const MenuTitle = styled.h2`
  font-size: 0.875rem;
  color: ${(props) => props.theme.colors.text_dark}80;
  padding: 0.75rem 1.5rem;
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const Button = styled.button`
  border: none;
  background: none;
  border-radius: 0;
  height: 2.5rem;
  padding: 0 1.5rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text_dark};
  font-weight: 600;
  transition: all 0.2s;
  width: auto;
  text-align: left;

  &:hover {
    background-color: ${(props) => props.theme.colors.background_dark}10;
  }

  &:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.primary};
    outline-offset: -2px;
  }
`;

const Overlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: all 0.3s ease;
`;

const Nav = styled.nav<{ isOpen?: boolean }>`
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.colors.background_light};
  box-shadow: 5px 0px 12px -1px rgba(0, 0, 0, 0.05);
  width: ${(props) => (props.isOpen ? "280px" : 0)};
  height: 100vh;
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;

  .closeButton {
    position: sticky;
    top: 0;
    width: 100%;
    height: 3rem;
    min-height: 48px;
    font-size: 1rem;
    text-align: center;
    border-bottom: 1px solid ${(props) => props.theme.colors.background_dark}20;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: ${(props) => props.theme.colors.background_light};
    z-index: 1;

    &:hover {
      background-color: ${(props) => props.theme.colors.background_dark}10;
    }

    span {
      font-weight: 500;
    }
  }
`;

const MenuContent = styled.div`
  overflow-y: auto;
  height: calc(100vh - 3rem);

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.background_light};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.background_dark}40;
    border-radius: 0.125rem;
  }
`;
