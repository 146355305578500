import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import OrderedTable from "../../components/OrderedTable";
import { getAppGeneralReport } from "../../services/reportsService";
import theme from "../../styles/colors";
import { TemplateButton } from "../ProductRestrictions/RestrictionItem";

export default function AppExternalReports() {
  return (
    <section className="wrapper">
      <Title>Relatórios externos</Title>
      <DivFlex>
        <DivBlock>
          <strong>Instalação e desinstalação (Downloads)</strong>
          <span>
            Ao clicar no botão abaixo, você será redirecionado para páginas
            externas.
          </span>
          <span>Haverá necessidade de ter cadastro nos sites.</span>
          <DivBlock>
            <span>
              Caso não tenha, solicitar para Equipe Tech via chamado no{" "}
              <a href="https://sonomabr.atlassian.net/servicedesk/customer/portal/1/group/-1">
                ServiceDesk
              </a>
            </span>
            <DivFlex>
              <LinkButton href="https://appstoreconnect.apple.com/analytics/app/r:20230701:20230709/1581997866/metrics?annotationsVisible=true&chartType=singleaxis&measureKey=installs&zoomType=day">
                IOS
              </LinkButton>
              <LinkButton href="https://play.google.com/console/u/0/developers/6689878948517090165/download-reports/statistics?appId=4973477621803812032">
                Android
              </LinkButton>
            </DivFlex>
          </DivBlock>
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          <strong>Navegação de usuário MixPanel</strong>
          <span>
            Ao clicar no botão abaixo, você será redirecionado para páginas
            externas.
          </span>
          <span>Haverá necessidade de ter cadastro nos sites.</span>
          <DivBlock>
            <span>
              Caso não tenha, solicitar para Equipe Tech via chamado no{" "}
              <a href="https://sonomabr.atlassian.net/servicedesk/customer/portal/1/group/-1">
                ServiceDesk
              </a>
            </span>
            <DivFlex>
              <LinkButton href="https://mixpanel.com/project/2546759/view/3086835/app/boards#id=4350012">
                Dashboard
              </LinkButton>
              <LinkButton href="https://mixpanel.com/project/2546759/view/3086835/app/insights/?discover=1#report/36056080">
                Sessões por Dia
              </LinkButton>
              <LinkButton href="https://mixpanel.com/project/2546759/view/3086835/app/flows#Ptv21pj6ig9h">
                Flow Chart
              </LinkButton>
              <LinkButton href="https://mixpanel.com/project/2546759/view/3086835/app/insights#7owsUaZGRrkK">
                Páginas Mais Acessadas
              </LinkButton>
              <LinkButton href="https://mixpanel.com/project/2546759/view/3086835/app/insights#PhKAyF2bNekj">
                Crashes do App
              </LinkButton>
              <LinkButton href="https://mixpanel.com/project/2546759/view/3086835/app/events">
                Eventos Real Time
              </LinkButton>
              <LinkButton href="https://mixpanel.com/project/2546759/view/3086835/app/retention#J6zJSRwUKLYa">
                Retenção por dispositivo
              </LinkButton>
            </DivFlex>
          </DivBlock>
        </DivBlock>
      </DivFlex>
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;

  strong {
    font-size: 18px;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  span {
    margin-bottom: 0.5rem;
  }
`;

const LinkButton = styled.a`
  background: ${theme.colors.primary};
  color: ${theme.colors.text_light};
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.5rem;
  margin-top: 1rem;

  &:hover {
    opacity: 0.8;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 800px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
