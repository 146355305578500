const theme = {
  title: "colors",
  colors: {
    primary: "#85b074",
    secundary: "#8a8a8a",
    text_dark: "#3b3b3a",
    text_light: "#fff",
    background_light: "#fff",
    background_dark: "#3b3b3a",
    border: "#ccc",
    background_lighter: "#D3D3D3",
    wine_red: "#b11226",
  },
  chartColors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd"],
};

export default theme;
