import { getStorage, ref, getDownloadURL } from "@firebase/storage";
import { initializeApp } from "@firebase/app";
import configs from "../appConfigs";

export default async function getImageDownloadUrlFromFireBase(path: string) {
  try {
    const firebaseConfig = configs.firebaseConfig;
    const app = initializeApp(firebaseConfig);
    const storage = getStorage(app);
    const httpsReference = ref(storage, path);
    return await getDownloadURL(httpsReference);
  } catch (error) {
    return "";
  }
}
