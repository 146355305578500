import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";
import { DevicesTypes, SearchType } from "../pages/SearchReports";
import { formatDateIntoDayMonthYear } from "../utils/formatDateIntoDayMonthYear";
import { ClientQuantityReportT, ReportRow } from "./types";

export async function getClientsReport(minDate?: string, maxDate?: string) {
  try {
    const { data } = await axios.get(
      `${configs.appApiBaseUrl}/reports/client_created?minDate=${minDate}&maxDate=${maxDate}`
    );

    return data;
  } catch (error) {
    console.log("[getClientsReport]", error);
    toast.error(`Erro ao tentar obter relatório de cliente \n", ${error}`);
  }
}

export async function getOrdersReport(minDate?: string, maxDate?: string) {
  try {
    const { data } = await axios.get(
      `${configs.appApiBaseUrl}/reports/orders_created?minDate=${minDate}&maxDate=${maxDate}`
    );

    return data;
  } catch (error) {
    console.log("[getOrdersReport]", error);
    toast.error(`Erro ao tentar obter relatório de pedidos \n", ${error}`);
  }
}

export async function getClientsOrdersQuantityReport() {
  try {
    const { data } = await axios.get(
      `${configs.appApiBaseUrl}/reports/clients_orders_quantity`
    );

    const extractedData: ClientQuantityReportT[] = data.data;

    const orderedData = extractedData.sort((a, b) => a.orderQtd - b.orderQtd);

    return orderedData;
  } catch (error) {
    console.log("[getClientsOrdersQuantityReport]", error);
    toast.error(
      `Erro ao tentar obter relatório de quantidade de pedidos de clientes \n", ${error}`
    );
  }
}

export async function getSearches({
  query,
  device,
  startDate,
  endDate,
}: {
  query: string;
  device: DevicesTypes["device"];
  startDate: string;
  endDate: string;
}) {
  const searches = await axios.post(
    `${configs.appApiBaseUrl}/applogs/retrieve/search`,
    {
      query,
      device,
      startDate: new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000),
      endDate: new Date(new Date(endDate).getTime() + 24 * 60 * 60 * 1000),
    }
  );

  return (searches?.data?.data as SearchType[]) || [];
}

export async function getBestSellingProducts({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const products = await axios.post(
    `${configs.apiBaseUrl}/api/reports/bestsellers`,
    {
      startDate: new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000),
      endDate: new Date(new Date(endDate).getTime() + 24 * 60 * 60 * 1000),
    }
  );

  if (!products?.data?.reports) return [];

  return products?.data?.reports.map(
    (row: {
      pro_in_codigo: number;
      salesAmount: string;
      salesValue: string;
      produto: {
        pro_st_sku: string;
        pro_st_nomesite: string;
      };
      pedidoApp: {
        cup_in_codigo: number | null;
        cupom: {
          discountTotal: string;
        } | null;
      };
    }) => ({
      SKU: row.produto?.pro_st_sku || "",
      Nome: row.produto?.pro_st_nomesite || "",
      "Quantidade de Vendas": Number(row.salesAmount || 0),
      "Valor total em vendas": Number(row.salesValue || 0),
      "Valor total em descontos": Number(
        row.pedidoApp.cupom?.discountTotal || 0
      ),
    })
  );
}

export async function getAppGeneralReport({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const response = await axios.post(
    `${configs.apiBaseUrl}/api/reports/general`,
    {
      startDate: new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000),
      endDate: new Date(new Date(endDate).getTime() + 24 * 60 * 60 * 1000),
    }
  );

  if (!response?.data?.reports) return [];

  const formattedResult: ReportRow[] = Object.values(
    response?.data?.reports.reduce(
      (
        accumulator: { [key: string]: ReportRow } | ReportRow[],
        current: ReportRow
      ) => {
        const isBuyName = current.Nome.includes("[Buy]");
        if (isBuyName) {
          current.Nome = "Buy";
        }
        const key = `${current.Dia}_${current.Nome}`;
        current.Quantidade = Number(current.Quantidade);
        if (!Array.isArray(accumulator)) {
          if (!(accumulator as { [key: string]: ReportRow })[key]) {
            (accumulator as { [key: string]: ReportRow })[key] = { ...current };
          } else {
            (accumulator as { [key: string]: ReportRow })[key].Quantidade +=
              current.Quantidade;
          }
        } else {
          const existingItem = accumulator.find(
            (item) => item.Dia === current.Dia && item.Nome === current.Nome
          );
          if (existingItem) {
            existingItem.Quantidade += current.Quantidade;
          } else {
            accumulator.push(current);
          }
        }
        return accumulator;
      },
      []
    )
  );

  let accumulatedResult: { [key: string]: { [key: string]: number | string } } =
    {};

  formattedResult.forEach((row) => {
    if (!accumulatedResult[row.Dia]) {
      accumulatedResult[row.Dia] = {
        Dia: row.Dia,
        [row.Nome]: row.Quantidade,
      };
    } else {
      accumulatedResult[row.Dia] = {
        ...accumulatedResult[row.Dia],
        [row.Nome]: row.Quantidade,
      };
    }
  });

  return Object.keys(accumulatedResult).map((key) => ({
    Dia: formatDateIntoDayMonthYear(accumulatedResult[key].Dia as string),
    "Cadastros feitos": accumulatedResult[key]["AUTH [Cadastre-se]"] || 0,
    "Cliques em comprar": accumulatedResult[key].Buy || 0,
    "Finalizar pedido (Cart)":
      accumulatedResult[key]["CART [Finalizar Pedido cart]"] || 0,
    "Ir para pagamento":
      accumulatedResult[key]["CART [Ir para Pagamento]"] || 0,
    "Cartão de credito": accumulatedResult[key]["CART [card]"] || 0,
    Pix: accumulatedResult[key]["CART [pix]"] || 0,
    Boleto: accumulatedResult[key]["CART [boleto]"] || 0,
    "Finalizar pedido":
      accumulatedResult[key]["CART [Finalizar Pedido final]"] || 0,
  }));
}

export async function getAppReport({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const report = await axios.post(`${configs.apiBaseUrl}/api/reports/app`, {
    startDate: new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000),
    endDate: new Date(new Date(endDate).getTime() + 24 * 60 * 60 * 1000),
  });

  if (!report?.data?.reports)
    return {
      clientsReport: [],
      firstOrdersReport: [],
      ordersReport: [],
      revenueReport: [],
      tkmReport: [],
    };

  return {
    clientsReport: report.data.reports.clientsReport || [],
    firstOrdersReport: report.data.reports.firstOrdersReport || [],
    ordersReport: report.data.reports.ordersReport || [],
    revenueReport: report.data.reports.revenueReport || [],
    tkmReport: report.data.reports.tkmReport || [],
  };
}

export async function getAppRevenueReport({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const report = await axios.post(`${configs.apiBaseUrl}/api/reports/payment`, {
    startDate: new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000),
    endDate: new Date(new Date(endDate).getTime() + 24 * 60 * 60 * 1000),
  });

  if (!report?.data?.reports) return [];

  return report.data.reports;
}

export async function getAppClientsReport() {
  const report = await axios.post(`${configs.apiBaseUrl}/api/reports/clients`);

  if (!report?.data?.reports) return [];

  return report.data.reports;
}

export async function getAppShippingReport({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  const report = await axios.post(
    `${configs.apiBaseUrl}/api/reports/shipping`,
    {
      startDate: new Date(new Date(startDate).getTime() + 24 * 60 * 60 * 1000),
      endDate: new Date(new Date(endDate).getTime() + 24 * 60 * 60 * 1000),
    }
  );

  if (!report?.data?.reports) return [];

  return report.data.reports;
}

export async function getAppWeeklyReport() {
  const report = await axios.post(`${configs.apiBaseUrl}/api/reports/weekly`);

  if (!report?.data?.reports) return [];

  return report.data.reports;
}
