export default function interpolateColorHex(
  percent: number,
  color1: string,
  color2: string
): string {
  const hexToRgb = (hex: string): number[] => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const fullHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

    const result = shorthandRegex.exec(hex) || fullHexRegex.exec(hex);
    if (!result) {
      throw new Error(`Invalid color hex value: ${hex}`);
    }

    const [, r, g, b] = result;
    return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
  };

  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);

  const interpolate = (c1: number, c2: number, percent: number): number =>
    Math.round(c1 + (c2 - c1) * percent);

  const r = interpolate(r1, r2, percent);
  const g = interpolate(g1, g2, percent);
  const b = interpolate(b1, b2, percent);

  const rgbToHex = (r: number, g: number, b: number): string =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;

  return rgbToHex(r, g, b);
}
