import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { useCallback, useEffect, useState } from "react";
import OrderedTable from "../../components/OrderedTable";
import Papa from "papaparse";
import { SignatureProduct } from "../../services/types";
import getImageDownloadUrlFromFireBase from "../../utils/getImageDownloadUrlFromFireBase";
import { ProductImage } from "../../components/BannerManager/ProductSelector";
import ProductRestrictionsListButton from "../ProductRestrictions/ProductRestrictionsListButton";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { mockData, mockHeaders } from "../ProductRestrictions/mockData";
import ProductSelectorForSignatures from "./ProductSelectorForSignatures";
import theme from "../../styles/colors";
import {
  deleteProductFromSignature,
  getProductsSignature,
  updateSignature,
} from "../../services/signatureService";
import { findIndex } from "../../utils/findIndexFromArrayOfArray";

const months: string[] = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

function getMonthName(monthNumber: number) {
  const monthName = months[monthNumber];

  return monthName;
}

export default function SelectProduct() {
  const { id: planId } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState("");
  const [amountProduct, setAmountProduct] = useState(1);

  const [products, setProducts] = useState<any>();
  const [newProducts, setNewProducts] = useState<any>();
  const [shouldShowAddProducts, setShouldShowAddProducts] =
    useState<boolean>(false);

  const handleGetProducts = async () => {
    if (!planId) return;
    setIsWaitingApi(true);
    const products = await getProductsSignature(Number(planId));
    if (!products) return;
    setProducts(
      await _formatProductsForList({
        products: products,
        handleDelete,
      })
    );

    setIsWaitingApi(false);
  };

  const changeHandler = (event: any) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setNewProducts(results.data);
      },
    });
  };

  const handleDelete = async ({
    id,
    month,
  }: {
    id: string;
    month?: number;
  }) => {
    if (
      !window.confirm(`
    Atenção:
    Você está prestes a remover um produto da regionalização. 
    Esta ação é irreversível!
    Pressione "Ok" para confirmar a exclusão.`)
    )
      return;

    setIsWaitingApi(true);
    const res = await deleteProductFromSignature({
      productId: id,
      planId,
      productMonth: month,
    });
    if (res?.status && res.status < 400)
      toast.success(`Produto removido com sucesso!`, {
        pauseOnFocusLoss: false,
      });

    setIsWaitingApi(false);
    handleGetProducts();
  };

  const handleUpdateSignature = async () => {
    if (!planId) return;

    setIsWaitingApi(true);
    const year = Number(selectedMonthAndYear.split("-")[0]);
    const month = Number(selectedMonthAndYear.split("-")[1]) - 1;

    const res = await updateSignature({
      planId,
      newProducts,
      month,
      year,
      amount: amountProduct,
    });
    if (res?.status && res.status < 400)
      toast.success(`Produtos da Assinatura atualizado com sucesso!`);
    setIsWaitingApi(false);
    handleGetProducts();
    setNewProducts(null);
    setShouldShowAddProducts(false);
  };

  const renderTables = useCallback(() => {
    if (!products || (Array.isArray(products) && products.length === 0)) {
      return <></>;
    }

    const sortedProducts = products?.sort(
      (a: Record<string, any>, b: Record<string, any>) =>
        months.indexOf(a["Mês"]) - months.indexOf(b["Mês"])
    );
    const groupedProductsByMonth: Array<Array<Record<string, any>>> = [];

    sortedProducts?.forEach((product: Record<string, any>) => {
      const monthIndex = findIndex(groupedProductsByMonth, product, "Mês");

      if (monthIndex >= 0 && groupedProductsByMonth[monthIndex]) {
        return groupedProductsByMonth.splice(monthIndex, 1, [
          ...groupedProductsByMonth[monthIndex],
          product,
        ]);
      }
      return groupedProductsByMonth.push([product]);
    });

    return groupedProductsByMonth.map((productsByMonth) => (
      <OrderedTable
        rows={productsByMonth?.filter((item) => {
          if (search) {
            return (
              item.Nome.toLowerCase().includes(search.toLowerCase()) ||
              item["Sku Mln"].toLowerCase().includes(search.toLowerCase())
            );
          }
          return true;
        })}
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    handleGetProducts();
  }, []);

  return (
    <section className="wrapper">
      <Title>Selecionar produtos para Assinatura:</Title>
      <DivFlex>
        <DivBlock>
          <TemplateButton
            data={mockData}
            headers={mockHeaders}
            filename={"template_produtos_restricao.csv"}
            variant="light"
          >
            Baixar template
          </TemplateButton>
        </DivBlock>
        <DivBlock>
          <FileInput id="file" onChange={changeHandler} />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => {
              setShouldShowAddProducts((state) => !state);
              setNewProducts(null);
            }}
            disabled={isWaitingApi}
          >
            {shouldShowAddProducts ? "Cancelar" : "Adicionar Produto"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleUpdateSignature}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Salvar"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => navigate(`/signature`)}
            disabled={isWaitingApi}
            customBackground={theme.colors.wine_red}
          >
            {isWaitingApi ? "Carregando..." : "Voltar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <SectionBreak />
      {shouldShowAddProducts && (
        <ProductSelectorForSignatures
          setProd={async (_product) => {
            setNewProducts(
              await _formatProductsForList({
                products: [
                  {
                    sgp_in_month: _product.month,
                    produto: {
                      pro_in_codigo: _product.productId,
                      pro_st_sku: _product.sku,
                      pro_st_descricao: _product.nome,
                      imagens: [
                        {
                          pim_st_imagesrc: _product.imageUrl,
                        },
                      ],
                    },
                  },
                ],
              })
            );
          }}
        />
      )}
      {newProducts && (
        <>
          <DivFlex>
            <DivBlock>
              <strong>Produto sendo adicionado:</strong>
              <small>Clique em salvar para continuar</small>
              <OrderedTable rows={newProducts} />
            </DivBlock>
          </DivFlex>
          <DivFlex>
            <DivBlock>
              <strong>Selecione Mês/Ano de referência para o produto:</strong>
              <FormInput
                type="month"
                setValue={setSelectedMonthAndYear}
                value={selectedMonthAndYear}
                width={"200"}
                height={"50"}
                borderRadius={"10"}
                title={""}
              />
            </DivBlock>
          </DivFlex>
          <DivFlex>
            <DivBlock style={{ marginTop: "20px" }}>
              <strong>Selecione a quantidade para o produto:</strong>
              <FormInput
                type="number"
                setValue={setAmountProduct}
                value={amountProduct}
                width={"200"}
                height={"50"}
                borderRadius={"10"}
                title={""}
              />
            </DivBlock>
          </DivFlex>
        </>
      )}
      {!shouldShowAddProducts && (
        <DivFlex>
          <DivBlock>
            <FormInput
              title="Busca"
              setValue={setSearch}
              value={search}
              width="250px"
              height="2rem"
              borderRadius="2px"
            />
          </DivBlock>
          <DivBlock>
            <Button
              variant="light"
              onClick={() => setSearch("")}
              disabled={isWaitingApi}
            >
              {isWaitingApi ? "Carregando..." : "Limpar"}
            </Button>
          </DivBlock>
        </DivFlex>
      )}
      {renderTables()}
    </section>
  );
}

async function _formatProductsForList({
  products,
  handleDelete,
}: {
  products: SignatureProduct[];
  handleDelete?: ({
    id,
    month,
  }: {
    id: string;
    month?: number;
  }) => Promise<void>;
}) {
  return await Promise.all(
    products?.map(async (item) => {
      const productImg = await getImageDownloadUrlFromFireBase(
        item.produto?.imagens?.[0]?.pim_st_imagesrc
      );

      return {
        Foto: (
          <ProductImage
            src={
              productImg ||
              "https://bo-prod-test.sonoma.com.br/media/wysiwyg/wine-loading.gif"
            }
            alt={item.produto.pro_st_descricao}
          />
        ),
        Nome: item.produto.pro_st_descricao,
        "Sku Mln": item.produto.pro_st_sku,
        ...(item.produto?.estoque?.[0]?.est_de_saldo && {
          Estoque: Number(item.produto?.estoque?.[0]?.est_de_saldo || 0),
        }),
        Quantidade: item.sgp_in_amount,
        Mês: getMonthName(item.sgp_in_month),
        Ano: item.sgp_in_year,
        ...(handleDelete && {
          "Remover Produto": (
            <ProductRestrictionsListButton
              id={item.produto.pro_in_codigo.toString()}
              month={item.sgp_in_month}
              action={handleDelete}
              type="delete"
            />
          ),
        }),
      };
    })
  );
}

const FileInput = styled.input.attrs({
  type: "file",
  accept: ".csv",
})`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0;
  height: 2.5rem;
  padding: 0.25rem 2rem;
  cursor: pointer;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text_light};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }

  &::file-selector-button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 0;
    cursor: pointer;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text_light};
    font-weight: 600;
    width: max-content;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SectionBreak = styled.div`
  display: flex;
  margin-top: 1rem;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

interface DownloadCSVButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  variant: "dark" | "light";
  customBackground?: string | null;
  disabled?: boolean;
}

export const TemplateButton = styled(CSVLink)<DownloadCSVButtonProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border: none;
  background: ${(props) =>
    props.customBackground
      ? props.customBackground
      : props.theme.colors.primary};
  border-radius: 0;
  height: 2.5rem;
  padding: 0 2rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) =>
    props.variant === "light"
      ? props.theme.colors.text_light
      : props.theme.colors.text_dark};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }
`;
