export function formatWeekRange(
  startDate: Date | undefined,
  endDate: Date | undefined
): string {
  if (!startDate || !endDate) return "-";

  const startMonth = startDate.toLocaleString("en-US", { month: "short" });
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const year = startDate.getFullYear();

  return `${startMonth} ${startDay}-${endDay} ${year}`;
}
