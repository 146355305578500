import { useEffect, useState } from "react";
import styled from "styled-components";
import BannerManager from "../../components/BannerManager";
import BannersAggregator from "../../components/BannerManager/BannersAggregator";
import { getBanners } from "../../services/bannersService";
import { Banners as BannersT } from "../../services/types";

export default function Banners() {
  const [banners, setBanners] = useState<BannersT>();

  const handleLoadBanners = async () => {
    const res = await getBanners();
    setBanners(res?.data.banners);
  };

  useEffect(() => {
    handleLoadBanners();
  }, []);

  return (
    <section className="wrapper">
      {banners && (
        <>
          <Title>Banners Home</Title>
          <BannerManager
            location="stripe"
            banners={banners.stripe || []}
            title={"Banner Tarja"}
          />
          <BannerManager
            location="main"
            banners={banners.main || []}
            title={"Banner Principal"}
          />
          <BannerManager
            location="slim1"
            banners={banners.slim1 || []}
            title={"Banner 1"}
          />
          <BannerManager
            location="slim2"
            banners={banners.slim2 || []}
            title={"Banner 2"}
          />
          <BannerManager
            location="slim3"
            banners={banners.slim3 || []}
            title={"Banner 3"}
          />
          <BannerManager
            location="slim4"
            banners={banners.slim4 || []}
            title={"Banner 4"}
          />
          <BannerManager
            location="slim5"
            banners={banners.slim5 || []}
            title={"Banner 5"}
          />

          <Title>Banners Busca</Title>
          <BannerManager
            location="search"
            banners={banners.search || []}
            title={"Banners Busca"}
          />

          <Title>Banners Categorias</Title>
          <BannersAggregator
            banners={banners.category || []}
            location={"category"}
            title={"Banners da categoria:"}
          />

          <Title>Banners Coleções</Title>
          <BannersAggregator
            banners={banners.cluster || []}
            location={"cluster"}
            title={"Banners da coleção:"}
          />
        </>
      )}
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;
