import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OrderedTable from "../../components/OrderedTable";
import { getAllTasks } from "../../services/freightService";
import { toast } from "react-toastify";

const FreightTaskList = () => {
  const [tasks, setTasks] = useState([]);

  const getTasks = async () => {
    try {
      const data = await getAllTasks();
      setTasks(data || []);
    } catch (error) {
      toast.error("Erro ao buscar lista de uploads de fretes");
    }
  };

  useEffect(() => {
    getTasks();
  }, []);

  return (
    <section className="wrapper">
      <Title>Lista Upload de Frete</Title>
      <OrderedTable rows={tasks} />
    </section>
  );
};

export default FreightTaskList;

const Title = styled.h1`
  margin: 2px;
  padding: 20px;
`;
