import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import OrderedTable from "../../components/OrderedTable";
import { getBestSellingProducts } from "../../services/reportsService";
import { TemplateButton } from "../ProductRestrictions/RestrictionItem";

export default function ProductsDashboard() {
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(() => {
    const date = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    return date.toISOString().split("T")[0];
  });
  const [endDate, setEndDate] = useState<string>(() => {
    const date = new Date(new Date().getTime());
    return date.toISOString().split("T")[0];
  });

  const [bestSellingProducts, setBestSellingProducts] = useState([]);

  async function handleGetBestSellingProducts() {
    try {
      setIsWaitingApi(true);
      const res = await getBestSellingProducts({
        startDate,
        endDate,
      });
      setBestSellingProducts(res);
      setIsWaitingApi(false);
    } catch (error) {
      setIsWaitingApi(false);
      toast.error("Erro ao buscar produtos");
    }
  }

  useEffect(() => {
    handleGetBestSellingProducts();
  }, []);

  return (
    <section className="wrapper">
      <Title>Produtos mais vendidos</Title>
      <DivFlex>
        <DivBlock>
          <DatePicker
            title="Data Início"
            value={startDate}
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <DatePicker
            title="Data Final"
            value={endDate}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <Button
          variant="light"
          onClick={handleGetBestSellingProducts}
          disabled={isWaitingApi}
        >
          {isWaitingApi ? "Carregando..." : "Buscar"}
        </Button>
        <TemplateButton
          disabled={isWaitingApi || !bestSellingProducts?.[0]}
          style={{ marginLeft: "8px" }}
          data={bestSellingProducts?.[0] ? bestSellingProducts : []}
          headers={
            bestSellingProducts?.[0] ? Object.keys(bestSellingProducts[0]) : []
          }
          filename={"products_bestsellers.csv"}
          variant="light"
        >
          {bestSellingProducts?.[0]
            ? "Baixar Relatório"
            : "Carregando dados..."}
        </TemplateButton>
      </DivFlex>
      <OrderedTable rows={bestSellingProducts} />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
