function formatDate(_data: string) {
  const US_TIMEZONE = 3;

  const data = new Date(_data);
  data.setHours(data.getHours() - US_TIMEZONE);

  const dateTimeSplit = data.toISOString().split("T");
  const time = dateTimeSplit[1].split(":");

  const dateSplit = dateTimeSplit[0].split("-");

  return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]} ${time[0]}:${time[1]}`;
}

export { formatDate };
