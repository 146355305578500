const clientReportHeader = [
  { label: "Quantidade de clientes", key: "total" },
  { label: "Clientes com pedidos", key: "withOrder" },
];

const ordersReportHeader = [
  { label: "Pedidos criados", key: "ordersCreated" },
  { label: "Pedidos aprovados", key: "ordersApproved" },
  { label: "Valor total de pedidos criados", key: "totalCreated" },
  { label: "Valor total de pedidos aprovados", key: "totalApproved" },
];

const clientsOrdersQuantityHeader = [
  { label: "Quantidade de pedidos", key: "orderQtd" },
  { label: "Quantidade de clientes", key: "clientQtd" },
];

// convert prices to R$XX.XX, because
function formatOrderData(data: any) {
  const totalC = data.totalCreated.toString().split(".");
  const totalA = data.totalApproved.toString().split(".");

  const formatted = {
    ordersCreated: data.ordersCreated,
    ordersApproved: data.ordersApproved,
    totalCreated: `R$ ${totalC[0] || "0"} ${totalC[1]?.substring(0, 2) || ""}`,
    totalApproved: `R$ ${totalA[0] || "0"} ${totalA[1]?.substring(0, 2) || ""}`,
  };

  return formatted;
}

export {
  clientReportHeader,
  ordersReportHeader,
  clientsOrdersQuantityHeader,
  formatOrderData,
};
