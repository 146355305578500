import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import styled from "styled-components";

export default function ComposedBarChart({
  data,
  labelKey,
  lineLabelKey,
  width,
  height,
  title,
  colors,
}: {
  data: any[];
  labelKey: string;
  lineLabelKey: string;
  width: number;
  height: number;
  title?: string;
  colors: string[];
}) {
  function getUniqueKeys(data: any[]): string[] {
    const keys: string[] = [];

    for (const obj of data) {
      for (const key in obj) {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      }
    }

    return keys;
  }

  return (
    <GraphContainer>
      <Title>{title}</Title>
      <ComposedChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={labelKey} />
        <YAxis yAxisId={1} />
        <YAxis yAxisId={2} domain={[0, 120]} hide />
        <Tooltip />
        <Legend />
        {getUniqueKeys(data).map((key, index) => {
          if (labelKey === key) return null;
          if (lineLabelKey === key)
            return (
              <Line
                yAxisId={2}
                key={index}
                type="linear"
                dataKey={key}
                unit={"%"}
                strokeWidth={3}
                stroke={colors[index % colors.length]}
              />
            );
          return (
            <Bar
              yAxisId={1}
              key={index}
              dataKey={key}
              fill={colors[index % colors.length]}
            />
          );
        })}
      </ComposedChart>
    </GraphContainer>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;
