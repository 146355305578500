import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { createRestriction } from "../../services/restrictionsService";
import theme from "../../styles/colors";
import handleSetCep from "../../utils/handleSetCep";

export default function RestrictionNew() {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [cepStart, setCepStart] = useState<string>("");
  const [cepEnd, setCepEnd] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const handleCreateRestriction = async () => {
    if (!name.length || cepStart.length < 9 || cepEnd.length < 9)
      return toast.warning(
        "Por favor, preencha todos os campos corretamente antes de prosseguir. Certifique-se de que todas as informações foram inseridas corretamente para garantir um processamento adequado."
      );

    setIsWaitingApi(true);
    const response = await createRestriction({
      name,
      begin: cepStart.replace("-", ""),
      end: cepEnd.replace("-", ""),
    });

    if (response?.status && response?.status < 400)
      navigate(`/restriction/${response.data.id}`);

    setIsWaitingApi(false);
  };

  return (
    <section className="wrapper">
      <Title>Criar Regionalização</Title>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Cidade ou Região"
            setValue={setName}
            value={name}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Limite 30 caracteres"
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Início da faixa do CEP de exibição"
            setValue={(value: string) =>
              handleSetCep({ value, setState: setCepStart })
            }
            value={cepStart}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Limite 9 números"
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Fim da faixa do CEP de exibição"
            setValue={(value: string) =>
              handleSetCep({ value, setState: setCepEnd })
            }
            value={cepEnd}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Limite 9 números"
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <small>
          *Os produtos associados a essa regionalização estão visíveis somente
          para os usuários que estiverem dentro do range de CEP apontado acima.
        </small>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          <Button
            variant="dark"
            onClick={() => navigate(`/restrictions`)}
            disabled={isWaitingApi}
            customBackground={theme.colors.text_light}
            border="1px solid #85b074"
          >
            {isWaitingApi ? "Carregando..." : "Voltar"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleCreateRestriction}
            disabled={isWaitingApi}
          >
            {isWaitingApi
              ? "Carregando..."
              : "Criar e ir para seleção de produtos"}
          </Button>
        </DivBlock>
      </DivFlex>
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
