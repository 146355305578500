import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CupomT } from "../../services/types";
import styled from "styled-components";
import Button from "../../components/Button";
import { getAllCoupons } from "../../services/couponService";
import { toast } from "react-toastify";
import { formatPrice } from "../../utils/price";
import { formatDate } from "../../utils/data";
import { createGlobalStyle } from "styled-components";

export default function CoupomList() {
  const [coupons, setCoupons] = useState<CupomT[]>([]);
  const [loading, setLoading] = useState(false);
  const navigator = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const emailsCX = [
    "rafaela.nascimento@sonoma.com.br",
    "cleciane.oliveira@sonoma.com.br",
    "weslei.andrade@sonoma.com.br",
    "rarime.oliveira@sonoma.com.br",
    "giovanna.silva@sonoma.com.br",
    "isabela.lima@sonoma.com.br",
    "thiago.oliveira@sonoma.com.br",
  ];

  const storedEmail = localStorage.getItem("Email");

  const getCoupons = async () => {
    setLoading(true);
    try {
      const coupons = await getAllCoupons();
      setCoupons(coupons);
    } catch (error) {
      toast.error("Erro ao carregar cupons");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCoupons();
  }, []);

  const filteredCoupons = coupons.filter((coupon) => 
    coupon.cup_st_codigo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Main>
      <Header>
        <h1>Gerenciamento de Cupons</h1>
        <HeaderActions>
          {storedEmail && !emailsCX.includes(storedEmail) && (
            <Button
              variant="light"
              onClick={() => navigator("/coupons")}
              aria-label="Criar novo cupom"
            >
              Criar Novo Cupom
            </Button>
          )}
        </HeaderActions>
      </Header>

      <SearchContainer>
        <SearchInputWrapper>
          <SearchIcon />
          <SearchInput
            type="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Pesquisar cupom por código..."
            aria-label="Pesquisar cupons"
          />
        </SearchInputWrapper>
      </SearchContainer>

      <Content>
        {loading ? (
          <LoadingState>
            <Spinner />
            <p>Carregando cupons...</p>
          </LoadingState>
        ) : filteredCoupons?.length > 0 ? (
          <TableWrapper>
            <Table 
              role="table" 
              aria-label="Lista de Cupons"
              aria-describedby="tableDescription"
            >
              <caption id="tableDescription" className="sr-only">
                Lista de cupons com informações sobre código, valor, status e outras características
              </caption>
              <thead>
                <tr role="row">
                  <Th role="columnheader" scope="col">Nome do Cupom</Th>
                  <Th role="columnheader" scope="col">Valor</Th>
                  <Th role="columnheader" scope="col">Porcentagem</Th>
                  <Th role="columnheader" scope="col">Valor Mínimo</Th>
                  <Th role="columnheader" scope="col">Status</Th>
                  <Th role="columnheader" scope="col">Uso Máximo</Th>
                  <Th role="columnheader" scope="col">Frete Grátis</Th>
                  <Th role="columnheader" scope="col">Data Início</Th>
                  <Th role="columnheader" scope="col">Data Final</Th>
                  {storedEmail && !emailsCX.includes(storedEmail) && <Th role="columnheader" scope="col">Ações</Th>}
                </tr>
              </thead>
              <tbody>
                {filteredCoupons.map((coupon: CupomT) => (
                  <TableRow 
                    key={coupon.cup_in_codigo} 
                    role="row"
                    aria-label={`Cupom ${coupon.cup_st_codigo}`}
                  >
                    <Td role="cell">{coupon.cup_st_codigo}</Td>
                    <Td role="cell">{formatPrice(Number(coupon.cup_de_valor))}</Td>
                    <Td role="cell">{coupon.cup_de_porcentagem}%</Td>
                    <Td role="cell">{formatPrice(Number(coupon.cup_de_minvalue || "0"))}</Td>
                    <Td role="cell">
                      <StatusBadge 
                        active={coupon.cup_bo_ativo}
                        aria-label={`Status: ${coupon.cup_bo_ativo ? "Ativo" : "Inativo"}`}
                      >
                        {coupon.cup_bo_ativo ? "Ativo" : "Inativo"}
                      </StatusBadge>
                    </Td>
                    <Td role="cell">{coupon.cup_in_usomaximo || "Ilimitado"}</Td>
                    <Td role="cell">
                      <Badge>{coupon.cup_bo_fretegratis ? "Sim" : "Não"}</Badge>
                    </Td>
                    <Td role="cell">{formatDate(coupon.cup_dt_startDate)}</Td>
                    <Td role="cell">{formatDate(coupon.cup_dt_endDate)}</Td>
                    {storedEmail && !emailsCX.includes(storedEmail) && (
                      <Td role="cell">
                        <ActionButton
                          onClick={() =>
                            navigator(`/editCoupons/${coupon.cup_in_codigo}`, {
                              state: coupon,
                            })
                          }
                          aria-label={`Editar cupom ${coupon.cup_st_codigo}`}
                        >
                          Editar
                        </ActionButton>
                      </Td>
                    )}
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        ) : (
          <EmptyState>
            <p>Nenhum cupom encontrado</p>
            <Button variant="light" onClick={getCoupons}>
              Tentar Novamente
            </Button>
          </EmptyState>
        )}
      </Content>
    </Main>
  );
}

// Styled Components
const Main = styled.main`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h1 {
    font-size: 1.5rem;
    color: ${props => props.theme.colors.text_dark};
  }
`;

const HeaderActions = styled.div`
  display: flex;
  gap: 1rem;
`;

const Content = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.875rem;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: ${props => props.theme.colors.background_dark}10;
  }
`;

const Th = styled.th`
  padding: 1rem;
  font-weight: 600;
  color: ${props => props.theme.colors.text_dark};
  border-bottom: 2px solid ${props => props.theme.colors.border};
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  vertical-align: middle;
`;

interface StatusBadgeProps {
  active: boolean;
}

const StatusBadge = styled.span<StatusBadgeProps>`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: ${props => props.active 
    ? props.theme.colors.primary 
    : '#E0E0E0'};
  color: ${props => props.active 
    ? 'white' 
    : '#666666'};
`;

const Badge = styled.span`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.75rem;
  background-color: ${props => props.theme.colors.background_light};
  color: ${props => props.theme.colors.text_dark};
  border: 1px solid ${props => props.theme.colors.border};
`;

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.1);
  }
`;

const LoadingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 1rem;
  color: ${props => props.theme.colors.text_dark}80;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid ${props => props.theme.colors.background_dark}20;
  border-top: 4px solid ${props => props.theme.colors.primary};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 1rem;
  color: ${props => props.theme.colors.text_dark}80;
`;

const SearchContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    left: 1rem;
    color: ${props => props.theme.colors.text_dark}80;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0 1rem 0 2.75rem;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;

  &::placeholder {
    color: ${props => props.theme.colors.text_dark}80;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 3px ${props => props.theme.colors.primary}20;
    outline: none;
  }
`;

const GlobalStyle = createGlobalStyle`
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;

const SearchIcon = () => (
  <svg 
    width="18" 
    height="18" 
    viewBox="0 0 18 18" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path 
      d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" 
      fill="currentColor"
    />
  </svg>
);
