export interface categoryList {
  catId: number;
  catAnterior: number;
  descricao: string;
  subcategorias: categoryList[];
}

export interface formatedCategoryList {
  title: string;
  value: string;
}

export default function formatCategoriesRecursively(
  categories: categoryList[]
): formatedCategoryList[] {
  return formatCategoryList(categories);
}

function formatCategory({
  catId,
  descricao,
  subcategorias,
}: categoryList): formatedCategoryList[] {
  return [
    { value: catId.toString(), title: descricao },
    ...formatCategoryList(subcategorias),
  ];
}

function formatCategoryList(subcategorias: categoryList[]) {
  return subcategorias.flatMap((category) => formatCategory(category));
}
