import styled from "styled-components";

interface SonomaButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant: "dark" | "light";
  customBackground?: string | null;
  disabled?: boolean;
  border?: string;
}

export default function Button(props: SonomaButtonProps) {
  return <SonomaButton {...props} />;
}

const SonomaButton = styled.button<SonomaButtonProps>`
  margin-top: 1rem;
  border: ${(props) => (props.border ? props.border : "none")};
  background: ${(props) =>
    props.customBackground
      ? props.customBackground
      : props.theme.colors.primary};
  border-radius: 5px;
  height: 2.5rem;
  padding: 0 2rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) =>
    props.variant === "light"
      ? props.theme.colors.text_light
      : props.theme.colors.text_dark};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 60%;
  }
`;
