import { Navigate, Outlet } from 'react-router-dom'
import AuthContext from '../auth/authProvider';
import { useContext } from 'react';

export default function AuthRoutes() {
    const { auth } = useContext(AuthContext);
    
    return (
        auth?.refresh_token ? <Outlet/> : <Navigate to="/"/>
    )
}