import styled from "styled-components";
import { ClientT } from "../../../services/types";

export default function ClientData({ client }: { client: ClientT }) {
  const Data = ({ title, text }: { title: string; text: string }) => (
    <TextContainer style={{ flexDirection: "row", width: 1000 }}>
      <BoldText>{title}: </BoldText>
      {text}
    </TextContainer>
  );

  const endereco = JSON.parse(client.endereco);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <Title>Dados do Cliente</Title>
      <Data title="Nome" text={client.nome} />
      <Data title="CPF" text={client.cpf} />
      <Data
        title="Endereço"
        text={`${endereco.end_st_logradouro}, ${endereco.end_st_numero}`}
      />
      <Data title="Complemento" text={endereco.end_st_complemento} />
      <Data title="Bairro" text={endereco.end_st_bairro} />
      <Data title="CEP" text={endereco.end_st_cep} />
    </div>
  );
}

const Title = styled.p`
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const TextContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const BoldText = styled.p`
  font-weight: bold;
  display: inline;
`;
