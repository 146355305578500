import styled from "styled-components";
import DashboardGalleryItem from "./DashboardGalleryItem";

export default function Dashboard() {
  return (
    <section className="wrapper">
      <Title>Relatórios</Title>
      <DashboardGalery>
        <DashboardGalleryItem url={"/reports/orders"} title={"Pedidos"} />
        <DashboardGalleryItem url={"/reports/search"} title={"Busca"} />
        <DashboardGalleryItem url={"/reports/signature"} title={"Signature"} />
        <DashboardGalleryItem url={"/reports/bestsellers"} title={"Produtos"} />
        <DashboardGalleryItem url={"/reports/external"} title={"Externos"} />
        <DashboardGalleryItem url={"/reports/general"} title={"App Checkout"} />
        <DashboardGalleryItem url={"/reports/app"} title={"App Performance"} />
        <DashboardGalleryItem url={"/reports/shipping"} title={"Frete"} />
        <DashboardGalleryItem url={"/reports/payment"} title={"Receita"} />
        <DashboardGalleryItem url={"/reports/clients"} title={"Clientes"} />
        <DashboardGalleryItem url={"/reports/weekly"} title={"Semanal App"} />
      </DashboardGalery>
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const DashboardGalery = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  max-width: 80%;
`;
