export function getStartAndEndDateFromWeekNumber(
  weekNumber: string
): { startDate: Date; endDate: Date } | null {
  // Check if the input string has the correct format (YYYYWW).
  if (!/^\d{6}$/.test(weekNumber)) {
    return null; // Invalid format
  }

  // Extract the year and week components from the input string.
  const year = parseInt(weekNumber.substring(0, 4), 10);
  const week = parseInt(weekNumber.substring(4), 10);

  // Check if the week number is valid.
  if (week < 1 || week > 53) {
    return null; // Invalid week number
  }

  // Calculate the starting date of the week.
  const startDate = new Date(year, 0, 1); // January 1st of the year
  startDate.setDate(startDate.getDate() + 2 + (week - 1) * 7); // Move to the first day of the specified week

  // Calculate the ending date of the week (assuming Sunday is the last day of the week).
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6); // Move to the last day of the week

  return { startDate, endDate };
}
