import { FC } from 'react';
import styled from 'styled-components';

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  itemsPerPageOptions: number[];
  onItemsPerPageChange: (items: number) => void;
  itemsPerPage: number;
  resultsTotal: number;
  formatTotal?: (total: number) => string;
}

export const Pagination: FC<PaginationProps> = ({
  totalPages,
  currentPage,
  setCurrentPage,
  itemsPerPageOptions,
  onItemsPerPageChange,
  itemsPerPage,
  resultsTotal,
  formatTotal
}) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <PaginationContainer>
      <PaginationControls>
        <Select
          value={itemsPerPage}
          onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
        >
          {itemsPerPageOptions.map((option) => (
            <option key={option} value={option}>
              {option} itens
            </option>
          ))}
        </Select>

        <NavigationButtons>
          <Button onClick={handlePrevious} disabled={currentPage === 1}>
            Anterior
          </Button>
          <PageInfo>
            Página {currentPage} de {totalPages}
          </PageInfo>
          <Button onClick={handleNext} disabled={currentPage === totalPages}>
            Próxima
          </Button>
        </NavigationButtons>
      </PaginationControls>
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ResultsInfo = styled.div`
  color: ${props => props.theme.colors.text_dark};
  font-size: 0.875rem;
`;

const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Select = styled.select`
  height: 2rem;
  padding: 0 0.5rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  background-color: white;
`;

const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: ${props => props.theme.colors.background_dark}10;
  }
`;

const PageInfo = styled.span`
  color: ${props => props.theme.colors.text_dark};
  font-size: 0.875rem;
`;

export default Pagination; 