import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormInput from "../components/BannerManager/FormInput";
import Button from "../components/Button";
import OrderedTable from "../components/OrderedTable";
import { getOrdersErrorLogs } from "../services/auditService";

export default function AppOrdersErrorsLogs() {
  const [logs, setLogs] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  useEffect(() => {
    handleGetLogs();
  }, []);

  async function handleGetLogs() {
    try {
      setIsWaitingApi(true);
      const res = await getOrdersErrorLogs();
      setLogs(res);
      setIsWaitingApi(false);
    } catch (error) {
      toast.error(`Não foi possível buscar errors de pedidos\nErro:\n${error}`);
    }
  }

  return (
    <section className="wrapper">
      <Title>Log de erros de pedidos do App</Title>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Busca"
            setValue={setSearch}
            value={search}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleGetLogs}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Atualizar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <OrderedTable
        rows={logs?.filter((item: { Erro: string }) => {
          if (search) {
            return item.Erro.toLowerCase().includes(search.toLowerCase());
          }
          return true;
        })}
      />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
