import { useState } from "react";
import styled from "styled-components";
import { deleteBanner } from "../../services/bannersService";
import Button from "../Button";

import { FaPen } from "react-icons/fa";

export default function Banner({
  image_url,
  banner_title,
  id,
  selectBanner,
}: {
  image_url: string;
  id: number;
  banner_title?: string;
  selectBanner: (id: number) => void;
}) {
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [shouldShow, setShouldShow] = useState<boolean>(true);

  async function handleDelete() {
    setIsWaitingApi(true);
    try {
      await deleteBanner({ banner_id: id });
      setShouldShow(false);
    } catch (error: any) {
      alert(error.message);
    }
    setIsWaitingApi(false);
  }

  return shouldShow ? (
    <BannerWrapper>
      {banner_title && (
        <BannerTitle>
          {`${banner_title.slice(0, 16)}${
            banner_title.length > 16 ? "..." : ""
          }`}
          <FaPen
            onClick={() => selectBanner(id)}
            size={14}
            color="green"
            style={{ cursor: "pointer", marginLeft: 8 }}
          />
        </BannerTitle>
      )}

      <BannerImg src={image_url} title="Banner" />
      <Button variant="dark" onClick={handleDelete} disabled={isWaitingApi}>
        {isWaitingApi ? "Excluindo..." : "Excluir"}
      </Button>
    </BannerWrapper>
  ) : null;
}

const BannerImg = styled.img`
  display: block;
  width: auto;
  height: 128px;
  border-radius: 4px;
  border: 2px solid #8a8a8a;
  padding: 0.5rem;
  margin: 1rem;
`;

const BannerTitle = styled.strong`
  display: block;
  margin-top: -14px;
  position: absolute;
  top: 14px;
  background: #fff;
  padding: 4px;
`;

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    margin-top: 0;
    position: absolute;
    bottom: 0;
    height: 2rem;
    margin-bottom: 0.5rem;
  }
`;
