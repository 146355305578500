import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import OrderedTable from "../../components/OrderedTable";
import Papa from "papaparse";
import handleSetCep from "../../utils/handleSetCep";
import {
  deleteProductFromRestriction,
  getRestriction,
  updateRestriction,
} from "../../services/restrictionsService";
import { RestrictionProduct } from "../../services/types";
import getImageDownloadUrlFromFireBase from "../../utils/getImageDownloadUrlFromFireBase";
import { ProductImage } from "../../components/BannerManager/ProductSelector";
import ProductRestrictionsListButton from "./ProductRestrictionsListButton";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { mockData, mockHeaders } from "./mockData";
import ProductSelectorForRestriction from "./ProductSelectorForRestriction";
import theme from "../../styles/colors";

export default function RestrictionItem() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [cepStart, setCepStart] = useState<string>("");
  const [cepEnd, setCepEnd] = useState<string>("");
  const [products, setProducts] = useState<any>();
  const [newProducts, setNewProducts] = useState<any>();
  const [shouldShowAddProducts, setShouldShowAddProducts] =
    useState<boolean>(false);

  const [restrictionId, setRestrictionId] = useState<string | undefined>(id);

  const handleGetRestriction = async () => {
    if (!id) return;
    setIsWaitingApi(true);
    const restriction = await getRestriction({ id });
    if (!restriction) return;

    setName(restriction.ren_st_descricao);
    handleSetCep({
      value: restriction.ren_in_inicio_cep.toString(),
      setState: setCepStart,
    });
    handleSetCep({
      value: restriction.ren_in_final_cep.toString(),
      setState: setCepEnd,
    });
    setRestrictionId(restriction.ren_in_codigo);
    setProducts(
      await _formatProductsForList({
        products: restriction.produtorestricaoentrega,
        handleDelete,
      })
    );

    setIsWaitingApi(false);
  };

  const changeHandler = (event: any) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setNewProducts(results.data);
      },
    });
  };

  const handleDelete = async ({ id }: { id: string }) => {
    if (
      !window.confirm(`
    Atenção:
    Você está prestes a remover um produto da regionalização. 
    Esta ação é irreversível!
    Pressione "Ok" para confirmar a exclusão.`)
    )
      return;

    console.log(id, restrictionId);

    setIsWaitingApi(true);
    const res = await deleteProductFromRestriction({
      productId: id,
      restrictionId: restrictionId,
    });
    if (res?.status && res.status < 400)
      toast.success(`Produto removido com sucesso!`, {
        pauseOnFocusLoss: false,
      });
    setIsWaitingApi(false);
    handleGetRestriction();
  };

  const handleUpdateRestriction = async () => {
    if (!restrictionId) return;

    setIsWaitingApi(true);

    const res = await updateRestriction({
      restrictionId,
      begin: cepStart.replace("-", ""),
      end: cepEnd.replace("-", ""),
      newProducts,
    });
    if (res?.status && res.status < 400)
      toast.success(`Regionalização atualizada com sucesso!`);
    setIsWaitingApi(false);
    handleGetRestriction();
    setNewProducts(null);
    setShouldShowAddProducts(false);
  };

  useEffect(() => {
    handleGetRestriction();
  }, []);

  return (
    <section className="wrapper">
      <Title>Regionalização de produtos:</Title>
      <span>{name}</span>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Início da faixa do CEP de exibição"
            setValue={(value: string) =>
              handleSetCep({ value, setState: setCepStart })
            }
            maxLength={9}
            value={cepStart}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Fim da faixa do CEP de exibição"
            setValue={(value: string) =>
              handleSetCep({ value, setState: setCepEnd })
            }
            maxLength={9}
            value={cepEnd}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          <TemplateButton
            data={mockData}
            headers={mockHeaders}
            filename={"template_produtos_restricao.csv"}
            variant="light"
          >
            Baixar template
          </TemplateButton>
        </DivBlock>
        <DivBlock>
          <FileInput id="file" onChange={changeHandler} />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => {
              setShouldShowAddProducts((state) => !state);
              setNewProducts(null);
            }}
            disabled={isWaitingApi}
          >
            {shouldShowAddProducts ? "Cancelar" : "Adicionar Produto"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleUpdateRestriction}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Salvar"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => navigate(`/restrictions`)}
            disabled={isWaitingApi}
            customBackground={theme.colors.wine_red}
          >
            {isWaitingApi ? "Carregando..." : "Voltar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <SectionBreak />
      {shouldShowAddProducts && (
        <ProductSelectorForRestriction
          setProd={async (_product) => {
            setNewProducts(
              await _formatProductsForList({
                products: [
                  {
                    produto: {
                      pro_in_codigo: _product.productId,
                      pro_st_sku: _product.sku,
                      pro_st_descricao: _product.nome,
                      imagens: [
                        {
                          pim_st_imagesrc: _product.imageUrl,
                        },
                      ],
                    },
                  },
                ],
              })
            );
          }}
        />
      )}
      {newProducts && (
        <DivFlex>
          <DivBlock>
            <strong>Produtos sendo adicionados:</strong>
            <small>Clique em salvar para continuar</small>
            <OrderedTable rows={newProducts} />
          </DivBlock>
        </DivFlex>
      )}
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Busca"
            setValue={setSearch}
            value={search}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => setSearch("")}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Limpar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <OrderedTable
        rows={products?.filter((item: { "Sku Mln": string; Nome: string }) => {
          if (search) {
            return (
              item.Nome.toLowerCase().includes(search.toLowerCase()) ||
              item["Sku Mln"].toLowerCase().includes(search.toLowerCase())
            );
          }
          return true;
        })}
      />
    </section>
  );
}

async function _formatProductsForList({
  products,
  handleDelete,
}: {
  products: RestrictionProduct[];
  handleDelete?: ({ id }: { id: string }) => Promise<void>;
}) {
  return await Promise.all(
    products.map(async (item) => {
      const productImg = await getImageDownloadUrlFromFireBase(
        item.produto?.imagens?.[0]?.pim_st_imagesrc
      );

      return {
        Foto: (
          <ProductImage
            src={
              productImg ||
              "https://bo-prod-test.sonoma.com.br/media/wysiwyg/wine-loading.gif"
            }
            alt={item.produto.pro_st_descricao}
          />
        ),
        Nome: item.produto.pro_st_descricao,
        "Sku Mln": item.produto.pro_st_sku,
        ...(item.produto?.estoque?.[0]?.est_de_saldo && {
          Estoque: Number(item.produto?.estoque?.[0]?.est_de_saldo || 0),
        }),
        ...(handleDelete && {
          "Remover Produto": (
            <ProductRestrictionsListButton
              id={item.produto.pro_in_codigo.toString()}
              action={handleDelete}
              type="delete"
            />
          ),
        }),
      };
    })
  );
}

const FileInput = styled.input.attrs({
  type: "file",
  accept: ".csv",
})`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0;
  height: 2.5rem;
  padding: 0.25rem 2rem;
  cursor: pointer;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text_light};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }

  &::file-selector-button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 0;
    cursor: pointer;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text_light};
    font-weight: 600;
    width: max-content;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SectionBreak = styled.div`
  display: flex;
  margin-top: 1rem;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

interface DownloadCSVButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  variant: "dark" | "light";
  customBackground?: string | null;
  disabled?: boolean;
}

export const TemplateButton = styled(CSVLink)<DownloadCSVButtonProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border: none;
  background: ${(props) =>
    props.customBackground
      ? props.customBackground
      : props.theme.colors.primary};
  border-radius: 5px;
  height: 2.5rem;
  padding: 0 2rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) =>
    props.variant === "light"
      ? props.theme.colors.text_light
      : props.theme.colors.text_dark};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }
`;
