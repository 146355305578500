import AuthContext from '../auth/authProvider';
import { useContext } from 'react';
import styled from 'styled-components';

export default function LogoutButton() {
    const { authHandler } = useContext(AuthContext);
    return (
        <Button onClick={authHandler.logout}>Sair</Button>
    )
}



export const Button = styled.button`
    border: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 0;
    height: 2.5rem;
    padding: 0 2rem;
    cursor: pointer;
    font-size: 14px;
    color: ${props => props.theme.colors.text_dark};
    font-weight: 600;
`;