import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

export async function getRestrictions() {
  try {
    const restrictions = await axios.get(
      `${configs.appApiBaseUrl}/produtos/restriction`
    );

    return restrictions?.data?.data;
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar regionalização de produtos da API \n", ${error}`
    );
  }
}

export async function deleteRestriction({ id }: { id: string }) {
  try {
    return await axios.delete(`${configs.apiBaseUrl}/api/restriction/${id}`);
  } catch (error) {
    toast.error(`Erro ao tentar excluir regionalização \n", ${error}`);
  }
}

export async function createRestriction({
  name,
  begin,
  end,
}: {
  name: string;
  begin: string;
  end: string;
}) {
  try {
    const restrictions = await axios.post(
      `${configs.apiBaseUrl}/api/restriction`,
      {
        name,
        begin,
        end,
      }
    );

    return restrictions;
  } catch (error) {
    toast.error(`Erro ao tentar criar regionalização \n", ${error}`);
  }
}

export async function getRestriction({ id }: { id: string }) {
  try {
    const restrictions = await axios.get(
      `${configs.appApiBaseUrl}/produtos/restriction/${id}`
    );

    return restrictions?.data?.data?.[0];
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar regionalização de produtos da API \n", ${error}`
    );
  }
}

export async function deleteProductFromRestriction({
  restrictionId,
  productId,
}: {
  restrictionId: string | undefined;
  productId: string | undefined;
}) {
  try {
    if (!restrictionId || !productId) return;

    return await axios.delete(
      `${configs.apiBaseUrl}/api/restriction/${restrictionId}/product/${productId}`
    );
  } catch (error) {
    toast.error(`Erro ao tentar excluir regionalização \n", ${error}`);
  }
}

export async function updateRestriction({
  restrictionId,
  begin,
  end,
  newProducts,
}: {
  restrictionId: string;
  begin: string;
  end: string;
  newProducts: [];
}) {
  try {
    return await axios.put(
      `${configs.apiBaseUrl}/api/restriction/${restrictionId}`,
      {
        begin,
        end,
        products: newProducts?.map((prod) => ({
          pro_st_sku: prod["Sku Mln"],
        })),
      }
    );
  } catch (error) {
    toast.error(`Erro ao tentar excluir regionalização \n", ${error}`);
  }
}
