import styled from "styled-components";
import LogoutButton from "./LogoutButton";
import { NavMenu } from "./NavMenu";

export default function Header() {
    return (
        <Navbar>
            <div className="wrapper">
                <NavMenu />
                <LogoutButton />
            </div>
        </Navbar>
    )
}

export const Navbar = styled.nav`
    height: 48px;
    width: 100%;
    box-shadow: 0px 5px 12px -1px rgba(0,0,0,0.05);
    padding: 0 ;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 90%;
    }
`;