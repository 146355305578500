import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { getCollections } from "../services/collectionService";
import { formatedCollectionItem } from "../services/types";
import theme from "../styles/colors";
import Dropdown from "./BannerManager/Dropdown";

export default function ClusterSelect({
  setState,
  initialValue,
}: {
  setState: Dispatch<SetStateAction<string[]>>;
  initialValue?: string[];
}) {
  const [clusters, setClusters] = useState<formatedCollectionItem[]>([]);
  const [clusterSelectionList, setClusterSelectionList] = useState<string[]>(
    initialValue || []
  );

  async function handleGetClusters() {
    setClusters(await getCollections());
  }

  function handleClusterSelect(selection: string) {
    setClusterSelectionList((clusters) => [
      ...clusters.filter((cluster) => cluster !== selection),
      selection,
    ]);
  }

  function handleRemoveSelectedCluster({
    id,
    clickEvent,
  }: {
    id: string;
    clickEvent: React.MouseEvent<HTMLElement>;
  }) {
    clickEvent.preventDefault();
    setClusterSelectionList(
      clusterSelectionList.filter((cluster) => cluster !== id)
    );
  }

  useEffect(() => {
    handleGetClusters();
  }, []);

  useEffect(() => {
    setState(clusterSelectionList);
  }, [clusterSelectionList]);

  return (
    <ClusterSelectContainer>
      <Label>Selecione uma coleção</Label>
      {clusters?.length ? (
        <Dropdown
          selected={""}
          setSelected={handleClusterSelect}
          options={clusters}
        />
      ) : (
        <Label>Carregando coleções</Label>
      )}
      {!!clusterSelectionList.length && !!clusters.length && (
        <Label>Seleção atual:</Label>
      )}
      <ClustersList>
        {!!clusters.length &&
          clusterSelectionList.map((cluster) => {
            return (
              <ClusterItem>
                {clusters.find((entry) => entry.value == cluster)?.title}
                <ClusterButton
                  onClick={(e) =>
                    handleRemoveSelectedCluster({ id: cluster, clickEvent: e })
                  }
                >
                  X
                </ClusterButton>
              </ClusterItem>
            );
          })}
      </ClustersList>
    </ClusterSelectContainer>
  );
}

const Label = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  display: inline;
  margin-right: 1rem;
`;

const ClusterSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 250px;
`;

const ClustersList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const ClusterItem = styled.span`
  font-size: 0.7rem;
  font-weight: bold;
  display: inline-block;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0.5rem;
  background-color: ${theme.colors.primary};
  border-radius: 4px;
  color: ${theme.colors.text_light};
`;

const ClusterButton = styled.button`
  font-size: 0.7rem;
  font-weight: bold;
  display: inline-block;
  margin-left: 0.3rem;
  width: 20px;
  height: 20px;
  background-color: ${theme.colors.background_dark};
  border-radius: 4px;
  color: ${theme.colors.text_light};
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.wine_red};
  }
`;
