import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import {
  getAllCarriers,
  uploadSheetFreight,
} from "../../services/freightService";
import { toast } from "react-toastify";

export interface ICarrier {
  tra_in_codigo: number;
  tra_st_cnpj: string | null;
  tra_st_fantasia: null;
  tra_st_nome: string | null;
  tra_st_cx_nome: string | null;
  tra_in_cod_transportadora: number;
  createdAt: Date;
  updatedAt: Date;
}

interface IResponseValidationHeaders {
  isValid: boolean;
  missingHeaders: string[];
  extraHeaders: string[];
}

export default function CreateFreight() {
  const [carriers, setCarriers] = useState<ICarrier[]>([]);
  const [idCarrier, setIdCarrier] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ carrier?: string; file?: string }>({});

  async function getCarriers() {
    setIsLoading(true);
    try {
      const carriers = await getAllCarriers();

      if (!carriers?.length) {
        throw Error(
          "Erro ao carregar transportadoras. Tente novamente mais tarde."
        );
      }

      const sortedCarriers = carriers.sort((a, b) => {
        const nameA = a.tra_st_cx_nome || a.tra_st_nome || "";
        const nameB = b.tra_st_cx_nome || b.tra_st_nome || "";
        return nameA.localeCompare(nameB);
      });

      setCarriers(sortedCarriers);
    } catch (error) {
      toast.error(
        "Erro ao carregar transportadoras. Tente novamente mais tarde."
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCarriers();
  }, []);

  const handleChangeCarrier = (e: ChangeEvent<HTMLSelectElement>) => {
    setIdCarrier(e.target.value);
    if (errors.carrier) {
      setErrors({ ...errors, carrier: undefined });
    }
  };

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(e.target.files?.[0] || null);
    if (errors.file) {
      setErrors({ ...errors, file: undefined });
    }
  };

  const validateCSVHeaders = (
    file: File
  ): Promise<IResponseValidationHeaders> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target?.result as string;
        const firstLine = text.split("\n")[0].trim();
        let delimiter = ",";
        if (firstLine.indexOf("\t") > -1) {
          delimiter = "\t";
        }
        const headers = firstLine
          .split(delimiter)
          .map((header) => header.trim());
        const expectedHeaders = [
          "fre_in_codigo",
          "fre_st_descricao",
          "tra_in_codigo",
          "fre_in_inicio_cep",
          "fre_in_final_cep",
          "fre_bi_inicio_peso",
          "fre_bi_final_peso",
          "fre_de_valor",
          "fre_st_custo_tempo",
          "fre_st_custo_tempo_bak",
          "createdAt",
          "updatedAt",
        ];

        const missingHeaders = expectedHeaders.filter(
          (header) => !headers.includes(header)
        );
        const extraHeaders = headers.filter(
          (header) => !expectedHeaders.includes(header)
        );

        const isValid =
          missingHeaders.length === 0 && extraHeaders.length === 0;

        resolve({
          isValid: isValid,
          missingHeaders,
          extraHeaders,
        });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  const validateForm = () => {
    const newErrors: { carrier?: string; file?: string } = {};
    let isValid = true;

    if (!idCarrier) {
      newErrors.carrier = "Selecione uma transportadora";
      isValid = false;
    }

    if (!selectedFile) {
      newErrors.file = "Selecione um arquivo";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSendSheet = async () => {
    if (!validateForm()) {
      return;
    }

    if (!(selectedFile instanceof File)) {
      setErrors({ ...errors, file: "O arquivo selecionado não é válido." });
      return;
    }

    setIsUploading(true);

    // Validação dos cabeçalhos do CSV
    try {
      const { isValid, missingHeaders, extraHeaders } =
        await validateCSVHeaders(selectedFile);

      if (!isValid) {
        let errorMessage = "Altere os cabeçalhos do arquivo. ";
        if (missingHeaders.length > 0) {
          errorMessage += `Campos obrigatórios: "${missingHeaders.join(
            ", "
          )}".\n`;
        }
        if (extraHeaders.length > 0) {
          errorMessage += `Campos excedentes: "${extraHeaders.join(", ")}".`;
        }
        toast.error(errorMessage);
        setIsUploading(false);
        return;
      }

      await uploadSheetFreight({ sheet: selectedFile, carrierId: idCarrier });
      toast.success("Planilha enviada para processamento");
      // Limpa os estados após envio
      setSelectedFile(null);
      setIdCarrier("");
      document.getElementById("planilha")?.setAttribute("value", "");
    } catch (error: any | Error) {
      toast.error(error.message || "Erro ao enviar planilha");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <section className="wrapper">
      <PageHeader>Upload Planilha de Frete</PageHeader>
      <FormContainer role="form" aria-labelledby="form-title">
        <h2 id="form-title" className="sr-only">
          Formulário de upload de planilha de frete
        </h2>

        <FormGroup>
          <Label htmlFor="carrier-select" isError={!!errors.carrier}>
            Transportadora:
            {errors.carrier && <ErrorMessage>{errors.carrier}</ErrorMessage>}
          </Label>
          <Select
            id="carrier-select"
            onChange={handleChangeCarrier}
            value={idCarrier}
            aria-required="true"
            aria-invalid={!!errors.carrier}
            disabled={isLoading || isUploading}
            isError={!!errors.carrier}
          >
            <option value="" disabled>
              {isLoading
                ? "Carregando transportadoras..."
                : "-- Escolha uma Transportadora --"}
            </option>
            {carriers?.map((carrier) => (
              <option key={carrier.tra_in_codigo} value={carrier.tra_in_codigo}>
                {carrier.tra_st_cx_nome
                  ? carrier.tra_st_cx_nome
                  : carrier.tra_st_nome}
              </option>
            ))}
          </Select>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="planilha" isError={!!errors.file}>
            Arquivo:
            {errors.file && <ErrorMessage>{errors.file}</ErrorMessage>}
          </Label>
          <FileInput
            type="file"
            name="planilha"
            id="planilha"
            accept=".xlsx, .csv"
            onChange={handleChangeFile}
            aria-required="true"
            aria-invalid={!!errors.file}
            aria-describedby="file-format"
            disabled={isUploading}
            isError={!!errors.file}
          />
          <HelpText id="file-format">Formatos aceitos: .xlsx, .csv</HelpText>
        </FormGroup>

        <SubmitButton
          type="button"
          onClick={handleSendSheet}
          disabled={isLoading || isUploading || !idCarrier || !selectedFile}
          aria-busy={isUploading}
        >
          {isUploading ? "Enviando..." : "Enviar"}
        </SubmitButton>

        {selectedFile && (
          <FileInfo>
            Arquivo selecionado: <strong>{selectedFile.name}</strong> (
            {Math.round(selectedFile.size / 1024)} KB)
          </FileInfo>
        )}
      </FormContainer>
    </section>
  );
}

const PageHeader = styled.h1`
  margin: 2px;
  padding: 20px;
  color: #333;
  font-size: 1.8rem;
`;

const Select = styled.select<{ isError?: boolean }>`
  width: 100%;
  height: 2.5rem;
  border: 2px solid
    ${(props) =>
      props.isError ? "#dc3545" : props.theme.colors.border || "#ced4da"};
  margin: 0.5rem 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text_dark || "#333"};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border-color: #85b074;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 176, 116, 0.25);
  }

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 576px) {
    padding: 16px;
    margin: 0 16px;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label<{ isError?: boolean }>`
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: ${(props) => (props.isError ? "#dc3545" : "#333")};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorMessage = styled.span`
  color: #dc3545;
  font-size: 0.85rem;
  font-weight: 400;
`;

const HelpText = styled.small`
  color: #6c757d;
  margin-top: 0.25rem;
  font-size: 0.75rem;
`;

const FileInfo = styled.div`
  background-color: #e9f5e9;
  padding: 10px;
  border-radius: 4px;
  font-size: 0.85rem;
  color: #2c662d;
`;

const FileInput = styled.input.attrs({ type: "file" })<{ isError?: boolean }>`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid ${(props) => (props.isError ? "#dc3545" : "#ced4da")};
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out;
  color: #333;

  &:focus {
    border-color: #85b074;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(133, 176, 116, 0.25);
  }

  &::-webkit-file-upload-button {
    padding: 8px 16px;
    background-color: #85b074;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #6d9a5d;
    }
  }

  &:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
  }
`;

const SubmitButton = styled.button`
  padding: 12px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #85b074;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out, transform 0.1s ease;
  position: relative;
  margin-top: 0.5rem;

  &:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }

  &:disabled:hover {
    background-color: #a0a0a0;
    transform: none;
  }

  &:hover:not(:disabled) {
    background-color: #6d9a5d;
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
  }

  &[aria-busy="true"]::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
