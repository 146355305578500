import axios from "axios";
import configs from "../appConfigs";

interface ProductProps {
  pro_in_codigo: number;
  pro_de_promotional_precopor: number;
  pro_de_promotional_precoatacado?: number;
  pro_dt_promotion_start: Date;
  pro_dt_promotion_end: Date;
}

export async function getProductsWithPromotion() {
  const products = await axios.get(
    `${configs.apiBaseUrl}/api/promotional_produtos`
  );

  return products.data.data;
}

export async function createOrEditPromotion(product: ProductProps) {
  const { pro_in_codigo, ...rest } = product;

  if (Number(rest?.pro_de_promotional_precoatacado) === 0) {
    delete rest.pro_de_promotional_precoatacado;
  }

  await axios.put(
    `${configs.apiBaseUrl}/api/promotional_produtos/${pro_in_codigo}`,
    rest
  );
}

export async function deletePromotion(productId: number) {
  await axios.delete(
    `${configs.apiBaseUrl}/api/promotional_produtos/${productId}`
  );
}
