import styled from "styled-components";

export default function FormCheckBox({
  title,
  setValue,
  value,
}: {
  title: string;
  setValue: (value: any) => void;
  value: any;
}) {
  function handleChangeValue() {
    setValue(!value);
  }
  return (
    <DivBlock>
      <Input
        type="checkbox"
        name={title}
        checked={!!value}
        onChange={handleChangeValue}
      />
      <Label htmlFor={title} onClick={handleChangeValue}>
        {title}
      </Label>
    </DivBlock>
  );
}

const Label = styled.label`
  color: ${(props) => props.theme.colors.text_dark};
  font-weight: 400;
  margin-left: 0.5rem;
  cursor: pointer;
`;

const Input = styled.input`
  font-size: 1rem;
  cursor: pointer;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;
