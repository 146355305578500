import styled from "styled-components";
import { NavLink } from 'react-router-dom';

export default function MenuItem({ to, title }: { to: string; title: string; }) {
  return (
    <Link to={to}>{title}</Link>
  )
}

export const Link = styled(NavLink)`
  color: ${props => props.theme.colors.text_dark};
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    background-color: ${props => props.theme.colors.background_dark}10;
  }

  &.active {
    color: ${props => props.theme.colors.primary};
    font-weight: 500;
    background-color: ${props => props.theme.colors.primary}10;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: ${props => props.theme.colors.primary};
    }
  }
`;