import { useState } from "react";
import Button from "../../components/Button";
import { toggleExpress } from "../../services/freightService";

export default function Freight() {
  const [isActive, setActive] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <section className="wrapper">
      <h1 style={{ marginBottom: 48, marginTop: 48 }}>Entrega expressa</h1>
      <p>Status: {isActive ? "ATIVO" : "DESATIVADO"}</p>
      <Button
        style={{ borderRadius: 7 }}
        variant="light"
        onClick={async () => {
          setLoading(true);
          const toggle = await toggleExpress();

          setActive(toggle);
          setLoading(false);
        }}
        disabled={loading}
      >
        {`${isActive ? "Desativar" : "Ativar"} entrega expressa`}
      </Button>
      {loading && <p style={{ marginTop: 16 }}>Carregando...</p>}
    </section>
  );
}
