import styled from "styled-components";

export const Main = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  display: block;
  padding: 0 1rem;
`;

export const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

export const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1rem;
  font-weight: 400;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 4rem;
  margin-top: 4rem;

  strong {
    font-size: 1.5rem;
  }
`;
