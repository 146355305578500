export function generateRandomPassword(length = 9) {
  const allowedCharacters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()-_=+";

  let password = "";

  for (let i = 0; i < length; i++) {
    const randomCharacter = allowedCharacters.charAt(
      Math.floor(Math.random() * allowedCharacters.length)
    );
    password += randomCharacter;
  }

  return password;
}
