import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";
import { convertToBrazilianDateTime } from "../utils/convertToBrazilianDateTime";

export async function getOrdersErrorLogs() {
  try {
    const logs = await axios.get(`${configs.apiBaseUrl}/api/applogs/orders`);

    return logs.data.data.map(
      (log: { Erro: string; Origem: string; "Data do Erro": string }) => {
        const parsedError = JSON.parse(log.Erro);
        return {
          ...log,
          Erro: parsedError?.message?.value || parsedError.message,
          "Data do Erro": convertToBrazilianDateTime(log["Data do Erro"]),
        };
      }
    );
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados dos pedidos da API \n", ${error}`
    );
  }
}
