import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { putBannersOrder } from "../../services/bannersService";
import { BannerT } from "../../services/types";
import Button from "../Button";
import ElementList, { ElementListItem } from "../ElementList";
import BannerComponent from "./Banner";

export default function BannerOrderList({
  shouldUpdate,
  openModal,
  banners,
  selectBanner,
}: {
  shouldUpdate: boolean;
  openModal: Dispatch<SetStateAction<boolean>>;
  banners: Array<BannerT>;
  selectBanner: (id: number | null) => void;
}) {
  const [itemList, setItemList] = useState<ElementListItem[]>([]);
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  useEffect(() => {
    populateBannerList();
  }, [shouldUpdate]);

  async function populateBannerList() {
    if (!banners.length) return;
    setItemList(
      banners.map((banner) => ({
        id: banner.banner_id.toString(),
        component: (
          <BannerComponent
            image_url={banner.image_url}
            banner_title={banner.banner_title}
            key={banner.banner_id.toString()}
            id={banner.banner_id}
            selectBanner={(id: number) => selectBanner(id)}
          />
        ),
      }))
    );
  }

  async function handleSaveBannersOrder() {
    setIsWaitingApi(true);
    try {
      const bannersOrdered = itemList.map((item, index) => ({
        banner_id: parseInt(item.id),
        position: index,
      }));

      await putBannersOrder({ bannersOrdered });
    } catch (error: any) {
      alert(error.message);
    }
    setIsWaitingApi(false);
  }

  return (
    <>
      <ElementList itemList={itemList} setItemList={setItemList} />

      <DivFlex>
        <Button
          variant="dark"
          onClick={handleSaveBannersOrder}
          disabled={isWaitingApi}
        >
          {isWaitingApi ? "Enviando..." : "Salvar ordenação"}
        </Button>
        <Button
          variant="dark"
          onClick={() => {
            selectBanner(null);
            openModal(true);
          }}
        >
          Adicionar Banner
        </Button>
      </DivFlex>
    </>
  );
}

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
