import { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import {
  getCollectionsAndCount,
  putCollection,
} from "../../services/collectionService";
import styled from "styled-components";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ClusterAndCountT } from "../../services/types";
import { toast } from "react-toastify";

export default function ClusterList() {
  const [clusters, setClusters] = useState<ClusterAndCountT[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigator = useNavigate();

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const getClusters = async () => {
    setLoading(true);
    try {
      const clusters = await getCollectionsAndCount();
      setClusters(clusters);
    } catch (error) {
      toast.error("Erro ao carregar coleções");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getClusters();
  }, []);

  const filteredClusters = clusters.filter((cluster) =>
    cluster.clu_st_nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function EditButton({ currentCluster }: { currentCluster: ClusterAndCountT }) {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [url, setUrl] = useState<string>(currentCluster.clu_st_url || "");

    const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(e.target.value);
    };

    const handleOnCancel = () => {
      setIsDisabled(true);
      setUrl(currentCluster.clu_st_url);
    };

    const handleOnSave = async () => {
      try {
        await putCollection({
          clu_in_codigo: currentCluster.clu_in_codigo,
          clu_st_url: url,
        });

        const newClusters = clusters.map((c) => {
          if (c.clu_in_codigo === currentCluster.clu_in_codigo) {
            return { ...c, clu_st_url: url };
          }
          return c;
        });

        setClusters(newClusters);
        setIsDisabled(true);
        toast.success("URL atualizada com sucesso!");
      } catch (error) {
        toast.error("Erro ao atualizar URL");
      }
    };

    return (
      <>
        <Td>
          <UrlInput
            disabled={isDisabled}
            value={url}
            onChange={handleOnChangeInput}
            aria-label={`URL da coleção ${currentCluster.clu_st_nome}`}
          />
        </Td>
        <Td>
          {isDisabled ? (
            <ActionButton
              onClick={() => setIsDisabled(false)}
              aria-label={`Editar URL da coleção ${currentCluster.clu_st_nome}`}
            >
              <FaRegEdit size={15} />
            </ActionButton>
          ) : (
            <ButtonGroup>
              <ActionButton onClick={handleOnSave} aria-label="Salvar alterações">
                Salvar
              </ActionButton>
              <ActionButton onClick={handleOnCancel} aria-label="Cancelar edição">
                Cancelar
              </ActionButton>
            </ButtonGroup>
          )}
        </Td>
      </>
    );
  }

  return (
    <Main>
      <Header>
        <h1>Gerenciamento de Coleções</h1>
        <Button
          variant="light"
          onClick={() => navigator("/clusters")}
          aria-label="Criar nova coleção"
        >
          Criar Nova Coleção
        </Button>
      </Header>

      <SearchContainer>
        <SearchInputWrapper>
          <SearchInput
            type="search"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Pesquisar por nome da coleção..."
            aria-label="Pesquisar coleções"
          />
        </SearchInputWrapper>
      </SearchContainer>

      <Content>
        {loading ? (
          <LoadingState>
            <Spinner />
            <p>Carregando coleções...</p>
          </LoadingState>
        ) : filteredClusters.length > 0 ? (
          <TableWrapper>
            <Table role="table" aria-label="Lista de Coleções">
              <thead>
                <tr role="row">
                  <Th role="columnheader" scope="col">Nome da Coleção</Th>
                  <Th role="columnheader" scope="col">Quantidade de Itens</Th>
                  <Th role="columnheader" scope="col">URL</Th>
                  <Th role="columnheader" scope="col">Ações</Th>
                  <Th role="columnheader" scope="col">Detalhes</Th>
                </tr>
              </thead>
              <tbody>
                {filteredClusters.map((cluster) => (
                  <TableRow 
                    key={cluster.clu_in_codigo}
                    role="row"
                  >
                    <Td role="cell">{cluster.clu_st_nome}</Td>
                    <Td role="cell">{parseInt(cluster.total_itens)}</Td>
                    <EditButton currentCluster={cluster} />
                    <Td role="cell">
                      <ActionButton
                        onClick={() =>
                          navigator(`/clustersDetails/${cluster.clu_in_codigo}`, {
                            state: cluster,
                          })
                        }
                        aria-label={`Visualizar detalhes da coleção ${cluster.clu_st_nome}`}
                      >
                        Visualizar
                      </ActionButton>
                    </Td>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </TableWrapper>
        ) : (
          <EmptyState>
            <p>Nenhuma coleção encontrada</p>
            <Button variant="light" onClick={getClusters}>
              Tentar Novamente
            </Button>
          </EmptyState>
        )}
      </Content>
    </Main>
  );
}

// Styled Components (reaproveitando os mesmos estilos das outras páginas)
const Main = styled.main`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
`;

// ... (adicionar todos os outros styled components que já criamos)

// Novos componentes específicos para esta página
const UrlInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  font-size: 0.875rem;
  
  &:disabled {
    background-color: ${props => props.theme.colors.background_light};
    cursor: not-allowed;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
`;

// ... (adicionar o resto dos styled components que já temos)

// Styled Components
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  h1 {
    font-size: 1.5rem;
    color: ${props => props.theme.colors.text_dark};
  }
`;

const Content = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.875rem;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: ${props => props.theme.colors.background_dark}10;
  }
`;

const Th = styled.th`
  padding: 1rem;
  font-weight: 600;
  color: ${props => props.theme.colors.text_dark};
  border-bottom: 2px solid ${props => props.theme.colors.border};
`;

const Td = styled.td`
  padding: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  vertical-align: middle;
`;

const ActionButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    filter: brightness(1.1);
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    left: 1rem;
    color: ${props => props.theme.colors.text_dark}80;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  height: 3rem;
  padding: 0 1rem 0 2.75rem;
  border: 2px solid ${props => props.theme.colors.border};
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;

  &::placeholder {
    color: ${props => props.theme.colors.text_dark}80;
  }

  &:focus {
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 3px ${props => props.theme.colors.primary}20;
    outline: none;
  }
`;

const LoadingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 1rem;
  color: ${props => props.theme.colors.text_dark}80;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid ${props => props.theme.colors.background_dark}20;
  border-top: 4px solid ${props => props.theme.colors.primary};
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 0;
  gap: 1rem;
  color: ${props => props.theme.colors.text_dark}80;
`;
