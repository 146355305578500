import { useEffect, useState } from "react";
import styled from "styled-components";

interface rowsFormatting {
  [key: string]: (row: string | number) => string | number;
}

export default function OrderedTable({
  rows,
  rowsFormatting,
}: {
  rows: any[] | undefined;
  rowsFormatting?: rowsFormatting;
}) {
  const [orderedRows, setOrderedRows] = useState<any[] | undefined>(rows);
  const [isOrderAsc, setIsOrderAsc] = useState<boolean>(false);
  const [lastOrdered, setLastOrdered] = useState<string | null>();

  useEffect(() => {
    setOrderedRows(rows);
  }, [rows]);

  function sortTable({ key }: { key: string }) {
    // Clone the orderedRows array to avoid mutating the original data
    const clonedRows = [...(orderedRows || [])];

    const sortedRows = clonedRows.sort((a, b) => {
      const valueA = a[key] || "-";
      const valueB = b[key] || "-";

      if (valueA === "-") return 1;
      if (valueB === "-") return -1;

      // Function to compare two numeric values
      const compareNumbers = (x: number, y: number) =>
        isOrderAsc ? x - y : y - x;

      // Function to compare two strings
      const compareStrings = (strA: string, strB: string) =>
        isOrderAsc ? strA.localeCompare(strB) : strB.localeCompare(strA);

      // Check the data type of the values
      if (typeof valueA === "number" && typeof valueB === "number") {
        return compareNumbers(valueA, valueB);
      } else if (typeof valueA === "string" && typeof valueB === "string") {
        const parsedA = parseFloat(valueA);
        const parsedB = parseFloat(valueB);

        if (!isNaN(parsedA) && !isNaN(parsedB)) {
          // Compare as percentages if both can be parsed as numbers
          return compareNumbers(parsedA, parsedB);
        } else {
          // Compare as strings if not both can be parsed as numbers
          return compareStrings(valueA, valueB);
        }
      } else if (valueA instanceof Date && valueB instanceof Date) {
        // Compare Date objects
        return compareNumbers(valueA.getTime(), valueB.getTime());
      }

      return 0; // Default case if values cannot be compared
    });

    // Set the last ordered key and toggle the sorting order
    setLastOrdered(key);
    setIsOrderAsc((currentState) => !currentState);

    // Update the orderedRows with the sorted data
    setOrderedRows(sortedRows);
  }

  function getUniqueKeys(data: any[]): string[] {
    const keys: string[] = [];

    for (const obj of data) {
      for (const key in obj) {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      }
    }

    return keys;
  }

  const uniqueKeys = getUniqueKeys(rows || []);

  return (
    <>
      {orderedRows?.length ? (
        <TableOfContents>
          <thead>
            <tr>
              {uniqueKeys.map((key) => (
                <th key={key} onClick={() => sortTable({ key })}>
                  {key !== "" &&
                    `${key} ${
                      lastOrdered === key
                        ? isOrderAsc
                          ? "\u2228"
                          : "\u2227"
                        : "\u2212"
                    }`}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {orderedRows.map((row, index) => (
              <tr key={index}>
                {uniqueKeys.map((key) => {
                  return (
                    <td key={key}>
                      {rowsFormatting?.[key]
                        ? rowsFormatting[key](row[key])
                        : row[key] || "-"}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </TableOfContents>
      ) : (
        <LoadingText>Nenhum resultado encontrado.</LoadingText>
      )}
    </>
  );
}

const TableOfContents = styled.table`
  font-size: 16px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  color: ${(props) => props.theme.colors.text_dark};
  margin: 3rem 0;

  tbody,
  thead {
    tr {
      border: 1px solid ${(props) => props.theme.colors.border};
      background-color: #eee;
      th {
        padding-top: 11px;
        padding-bottom: 11px;
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
        text-align: center;
        padding: 8px;
        cursor: pointer;
      }
      td {
        text-align: center;
        padding: 8px;
        display: table-cell;
        border: 1px solid ${(props) => props.theme.colors.border};

        button {
          margin: unset;
        }
        img {
          display: unset;
        }
      }
    }
    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.background_light};
    }
  }
`;

const LoadingText = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 3rem 0;
`;
