import axios from "axios";
import configs from "../appConfigs";
import { toast } from "react-toastify";
import { ICarrier } from "../pages/CreateFreight";

export async function toggleExpress() {
  const response = await axios.post(`${configs.apiBaseUrl}/api/express`);

  return response.data.active;
}

export async function getAllCarriers() {
  try {
    const response = await axios.get(`${configs.apiBaseUrl}/api/carriers`);
    return response.data.data as ICarrier[];
  } catch (error) {
    toast.error("Erro ao buscar transportadoras");
  }
}

export async function uploadSheetFreight({
  sheet,
  carrierId,
}: {
  sheet: File;
  carrierId: string;
}) {
  try {
    const formData = new FormData();

    formData.append("file", sheet, sheet.name);
    formData.append("carrierId", carrierId);

    return await axios.post(
      `${configs.apiBaseUrl}/api/freight/upload`,
      formData
    );
  } catch (error) {
    console.log({ error });
    throw error;
  }
}

export async function getAllTasks() {
  try {
    const response = await axios.get(
      `${configs.apiBaseUrl}/api/freight/uploads`
    );

    return response.data.data;
  } catch (error) {
    throw error;
  }
}
